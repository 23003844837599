import { ExperimentResult } from 'interfaces/experimentResults';
import { find, flatMap, fromPairs, groupBy, map, size } from 'lodash';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { FeatureMetadata, HeatmapType, ParsedResults } from './featureMetadata';
import { parseFeatures } from './parseFeatures';
import { parseHeatmaps } from './parseHeatmaps';

/**
 * For unpublished heatmaps, we want to rename them uniquely per flow class name and orchestration id
 * if there are multiple heatmaps with the same display name (for the same flow class name and orchestration id).
 * This is to avoid having multiple heatmaps with the same display name in the results sidebar.
 * @param heatmaps - unpublished heatmaps
 * @returns renamed heatmaps
 */
const renameUnpublishedHeatmapsUniquelyPerFlowOrchestrationAndDisplayName = (
  heatmaps: FeatureMetadata[]
): FeatureMetadata[] => {
  const heatmapsResultsSidebarGrouping = groupBy(
    heatmaps,
    // The results sidebar will group heatmaps by their result type / flow class name, orchestration id.
    // We then want them to be unique by their display name
    (heatmap) => `${heatmap.resultType || heatmap.flowClassName}-${heatmap.orchestrationId}-${heatmap.displayName}`
  );
  return flatMap(heatmapsResultsSidebarGrouping, (heatmapsGroup) => {
    if (size(heatmapsGroup) === 1) {
      // If there is only one heatmap in the group, return it as is
      return heatmapsGroup;
    }
    // If there are multiple heatmaps in the group, prefix the heatmap type to the display name (only for unpublished heatmaps)
    return map(heatmapsGroup, (heatmap) => {
      return {
        ...heatmap,
        displayName: `${
          heatmap.heatmapType === 'dzi'
            ? HeatmapType.Dzi
            : heatmap.heatmapType === 'parquet'
            ? HeatmapType.Parquet
            : heatmap.heatmapType === HeatmapType.Pmt
            ? 'Vector'
            : heatmap.heatmapType
        } - ${heatmap.displayName}`,
      };
    });
  });
};

/** For published heatmaps with the same display name, we want to keep the vector heatmap over the raster heatmap */
export const filterRedundantRasterHeatmaps = (heatmaps: FeatureMetadata[]): FeatureMetadata[] => {
  const heatmapsResultsSidebarGrouping = groupBy(
    heatmaps,
    (heatmap) => `${heatmap.resultType || heatmap.flowClassName}-${heatmap.orchestrationId}-${heatmap.displayName}`
  );
  return flatMap(
    heatmapsResultsSidebarGrouping,
    (heatmapsGroup) =>
      find(heatmapsGroup, { heatmapType: HeatmapType.Parquet }) ||
      find(heatmapsGroup, { heatmapType: HeatmapType.Pmt }) ||
      heatmapsGroup
  );
};

export const parseSlideExperimentResults = (
  slideResults: ExperimentResult[],
  formatBracketsOptions: FormatBracketsOptions,
  skipVectorHeatmaps = false
): ParsedResults => {
  const { publishedFeatures, internalFeatures, deletedFeatures } = parseFeatures(slideResults);

  const { publishedHeatmaps, publishableHeatmaps, internalHeatmaps, deletedHeatmaps } = parseHeatmaps(
    slideResults,
    formatBracketsOptions,
    skipVectorHeatmaps
  );

  const results: ParsedResults = {
    heatmaps: {
      publishedResults: filterRedundantRasterHeatmaps(publishedHeatmaps),
      internalResults: fromPairs(
        map(publishableHeatmaps, (heatmapList, key) => [
          key,
          renameUnpublishedHeatmapsUniquelyPerFlowOrchestrationAndDisplayName(heatmapList),
        ])
      ),
      deletedResults: renameUnpublishedHeatmapsUniquelyPerFlowOrchestrationAndDisplayName(deletedHeatmaps),
    },
    features: {
      publishedResults: publishedFeatures,
      internalResults: internalFeatures,
      deletedResults: deletedFeatures,
    },
    internalHeatmaps: fromPairs(
      map(internalHeatmaps, (heatmapList, key) => [
        key,
        renameUnpublishedHeatmapsUniquelyPerFlowOrchestrationAndDisplayName(heatmapList),
      ])
    ),
  };

  return results;
};
