import { keyBy } from 'lodash';

export const classNamesOptions = [
  {
    text: 'ROI',
    value: 'roi',
  },
  {
    text: 'ROI for inference',
    value: 'roi-for_inference',
  },
];

export const nonDynamicOption = {
  text: 'Non-dynamic',
  value: 'Non-dynamic',
  details: 'The legacy detection method, needs parameters created during "optimize parameters" step',
};

export const dynamicCellDetectionConfigOptions = [
  nonDynamicOption,
  {
    text: 'dynamic_v1',
    value: 'dynamic_v1',
    details: 'Vanilla version of dynamic',
  },
  {
    text: 'dynamic_v2_th05_x2',
    value: 'dynamic_v2_th05_x2',
    details:
      '[advised] Dynamic with better overdetection/underdetection balance (filters out "weak" cell candidates and "small" tumor cell candidates)',
  },
];

export const clearMlMachineTypeOptions = [
  {
    text: 'Spot',
    value: 'spot',
  },
  {
    text: 'On demand',
    value: 'on_demand',
  },
];

// apiModelValue according to algo/src/primitives/schemas/models.py
export const modelTypeArea = {
  text: 'Area',
  value: 'area',
  apiModelValue: 'model_areas',
};

export const modelTypeCell = {
  text: 'Cell',
  value: 'cell',
  apiModelValue: 'model_points',
};

export const modelTypeDefect = {
  text: 'Defect',
  value: 'defect',
  apiModelValue: 'model_defects',
};

export const modelTypeTsm = {
  text: 'TSM',
  value: 'tsm',
  apiModelValue: 'model_tsm',
};

export const modelTypeTls = {
  text: 'TLS',
  value: 'tls',
  apiModelValue: 'model_tls',
};

export const modelTypeNormalization = {
  text: 'Normalization',
  value: 'normalization',
  apiModelValue: 'model_mplex_normalization',
};

export const modelTypeClassification = {
  text: 'Classifications',
  value: 'classification',
  apiModelValue: 'model_classification',
};

export const modelTypeNucleiSegmentation = {
  text: 'Nuclei Segmentation',
  value: 'nucleiSegmentation',
  apiModelValue: 'model_nuclei_segmentation',
};

export const modelTypeBinaryClassifier = {
  text: 'Binary Classifier',
  value: 'binaryClassifier',
  apiModelValue: 'model_mplex_binary_classification',
};

export const modelTypeCellSegmentation = {
  text: 'Cell Segmentation',
  value: 'cellSegmentation',
  apiModelValue: 'model_cell_segmentation',
};

// Those are markers that are DNA Binding, so they show all cells
export const channelsToExtractOptions = [
  {
    text: 'dapi',
    value: 'dapi',
  },
  {
    text: 'hoechst',
    value: 'hoechst',
  },
];

export const modelTypeRunInferenceOptions = [modelTypeArea, modelTypeCell, modelTypeDefect];
export const modelTypeOptions = [
  modelTypeArea,
  modelTypeCell,
  modelTypeDefect,
  modelTypeTsm,
  modelTypeTls,
  modelTypeClassification,
  modelTypeNucleiSegmentation,
  modelTypeBinaryClassifier,
  modelTypeCellSegmentation,
];

export const modelTypesByValue = keyBy(modelTypeOptions, 'value');
export const modelTypesByApiModelValue = keyBy(modelTypeOptions, 'apiModelValue');
