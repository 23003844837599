import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DrawIcon from '@mui/icons-material/Draw';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PanToolIcon from '@mui/icons-material/PanTool';
import PushPinIcon from '@mui/icons-material/PushPin';
import SquareIcon from '@mui/icons-material/Square';

import { SlideInteractionMenuOptions } from './SlideInteractionOption';

export enum SlideAnnotationEditType {
  Delete = 'delete',
  Modify = 'modify',
  Polygon = 'polygon',
  Roi = 'roi',
  Point = 'point',
  Move = 'move',
}

// This is an internal type that we save inside properties of a feature.
// It is taken from the legacy backoffice codebase.
export enum ShapeSubTypes {
  Polygon = 'polygon',
  Rect = 'rect',
  Point = 'circle',
}

export const editTypeToShapeSubTypeMap = {
  [SlideAnnotationEditType.Polygon]: ShapeSubTypes.Polygon,
  [SlideAnnotationEditType.Roi]: ShapeSubTypes.Rect,
  [SlideAnnotationEditType.Point]: ShapeSubTypes.Point,
};

export const shapeSubTypeToEditTypeMap = {
  [ShapeSubTypes.Polygon]: SlideAnnotationEditType.Polygon,
  [ShapeSubTypes.Rect]: SlideAnnotationEditType.Roi,
  [ShapeSubTypes.Point]: SlideAnnotationEditType.Point,
};

export const slideAnnotationOptions: SlideInteractionMenuOptions[] = [
  {
    editType: SlideAnnotationEditType.Delete,
    title: 'Delete Individual Annotations',
    icon: DeleteOutlineIcon,
  },
  {
    editType: SlideAnnotationEditType.Modify,
    title: 'Modify Annotations',
    icon: DriveFileRenameOutlineIcon,
  },
  {
    editType: SlideAnnotationEditType.Move,
    title: 'Move Annotations',
    icon: PanToolIcon,
  },
  {
    editType: SlideAnnotationEditType.Polygon,
    title: 'Polygon',
    icon: DrawIcon,
  },
  {
    editType: SlideAnnotationEditType.Roi,
    title: 'ROI Selection',
    icon: SquareIcon,
  },
  {
    editType: SlideAnnotationEditType.Point,
    title: 'Point',
    icon: PushPinIcon,
  },
];
