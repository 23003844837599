import { Button, FormControlLabel, Grid, Switch } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { viewerAnnotationData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import useAnnotationsForViewer from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useAnnotations';
import React from 'react';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';
import useHandleUnload from 'utils/useHandleUnload';

export interface AnnotationChangesControlsProps {
  viewerIndex: number;
  slideId: string;
}

export const useAnnotationsAutoSaveParam = () => useQueryParam('autoSave', withDefault(BooleanParam, false));

export const AnnotationChangesControls: React.FC<React.PropsWithChildren<AnnotationChangesControlsProps>> = ({
  viewerIndex,
  slideId,
}) => {
  useSignals();
  const confirmWithModal = useConfirmation();
  const [autoSave, setAutoSave] = useAnnotationsAutoSaveParam();

  const {
    draftDataSaved,
    publishedAnnotationsSaved,
    saveDraftMutation,
    publishAnnotationsMutation,
    activeAnnotationAssignmentId,
    saveDraft,
  } = useAnnotationsForViewer({ slideId, viewerIndex });

  const onSaveDraft = () => {
    saveDraft({
      assignmentId: activeAnnotationAssignmentId,
      featureCollection: viewerAnnotationData[viewerIndex]?.value,
    });
  };

  useHandleUnload({ showAlert: !draftDataSaved });

  const publishAnnotations = async () => {
    if (
      await confirmWithModal({
        title: 'Publish annotations',
        text: 'Are you sure you want to publish this annotations?',
        confirmButtonProps: { title: 'Publish' },
        cancelButtonProps: { title: 'Cancel' },
      })
    ) {
      publishAnnotationsMutation.mutate({
        annotationAssignmentId: activeAnnotationAssignmentId,
        slideId,
      });
    }
  };

  const cancelEdit = async () => {
    if (
      await confirmWithModal({
        title: 'Cancel annotation changes',
        text: 'Are you sure you want to cancel?',
        confirmButtonProps: { title: 'Cancel' },
        cancelButtonProps: { title: 'Continue' },
      })
    ) {
      if (viewerAnnotationData[viewerIndex]) {
        viewerAnnotationData[viewerIndex].value = null;
      }
    }
  };

  const disablePublishButton =
    publishedAnnotationsSaved || !draftDataSaved || publishAnnotationsMutation.isLoading || saveDraftMutation.isLoading;

  return (
    activeAnnotationAssignmentId && (
      <Grid container direction="column" spacing={1} py={2}>
        <Grid item container spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              value="save"
              onClick={publishAnnotations}
              disabled={disablePublishButton}
            >
              {publishAnnotationsMutation.isLoading ? 'Publishing...' : 'Publish'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              value="save"
              onClick={onSaveDraft}
              disabled={draftDataSaved || saveDraftMutation.isLoading}
            >
              {saveDraftMutation.isLoading ? 'Saving...' : 'Save as draft'}
            </Button>
          </Grid>
          <Grid item>
            <Button color="secondary" value="cancel" onClick={cancelEdit} disabled={draftDataSaved}>
              Cancel
            </Button>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <FormControlLabel
              control={
                <Switch checked={autoSave} onChange={(event) => setAutoSave(event.target.checked)} color="primary" />
              }
              label="Auto Save"
            />
          </Grid>
        </Grid>
      </Grid>
    )
  );
};
