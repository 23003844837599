import { AnnotationAssignment } from 'interfaces/annotation';
import { AutomaticCondition } from 'interfaces/automaticCondition';
import {
  Input,
  NumberOption,
  OrchestrationData,
  OrchestrationDataType,
  orchestrationDataTypeNumbers,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import PostProcessingAction, {
  Input as PostProcessingActionInput,
  InputSource,
  InputType,
  MappingFilterMetadata,
  PostProcessingActionCreated,
} from 'interfaces/postProcessingAction';
import {
  cloneDeep,
  Dictionary,
  every,
  filter,
  find,
  findKey,
  first,
  flatMap,
  forEach,
  get,
  groupBy,
  includes,
  indexOf,
  isEmpty,
  isEqual,
  isObject,
  keyBy,
  last,
  map,
  set,
  size,
  split,
  uniq,
  values,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { NewCell, RegistrationDetails, SelectedAssignmentDetails, SlideRegistrationDetailsByOrchestration } from '.';
import { getNameByValue } from './FeaturesStep/NewCellsStain';
import { targetCellClassesInputKey } from './PostProcessingActions/PostProcessingAction';
import {
  convertLogicalQuery,
  convertLogicalQueryFromBackend,
  getSpecificInputError,
  isFeatureBetweenStains,
  neighborStainsInputKey,
  targetStainsInputKey,
} from './PostProcessingActions/utils';

// example to modelUrl - "artifact://models/e2e/Seagen_B6A/Lung/PDL1/segmentation/2695841c?hash_data=1dbcc14147d685590257aa679be77345"
// example to modelId - "2695841c"
export const getModelId = (modelUrl: string) => {
  return first(split(last(split(modelUrl, '/')), '?'));
};

// example to modelUrl - "artifact://tsm/{slide_id}.pickle?latest&meta.deps.model_id=75f8b1dc&meta.deps.override_annotations_timestamp=2023-08-27 14:48:53"
// example to override_annotations_timestamp - "2023-08-27 14:48:53"
export const getOverrideAnnotationsTimestamp = (modelUrl: string) => {
  return last(split(modelUrl, 'override_annotations_timestamp=')) ?? 'None';
};

// Combine the orchestration data from the previous and new data, if the data already exists, combine the options
// see examples in utils.spec.ts
export const combineOrchestrationMetadata = (
  orchestrationsData: OrchestrationData[],
  newData: OrchestrationData[]
): OrchestrationData[] => {
  if (isEmpty(orchestrationsData)) return newData;

  const combinedOrchestrationsData = cloneDeep(orchestrationsData);

  forEach(newData, (data) => {
    const existingData = find(orchestrationsData, { label: data.label, type: data.type });

    if (existingData) {
      const combinedOrchestrationData = cloneDeep(existingData);
      if (data?.options) {
        if (data?.type === OrchestrationDataType.Categorical) {
          combinedOrchestrationData.options = uniq([
            ...(combinedOrchestrationData.options as string[]),
            ...(data.options as string[]),
          ]);
        } else if (includes(orchestrationDataTypeNumbers, data?.type)) {
          const dataOptions = data?.options as NumberOption;
          const combinedOrchestrationDataOptions = combinedOrchestrationData?.options as NumberOption;
          combinedOrchestrationData.options = {
            min: Math.max(dataOptions?.min, combinedOrchestrationDataOptions?.min),
            max: Math.min(dataOptions?.max, combinedOrchestrationDataOptions?.max),
          };
        }
      }
      combinedOrchestrationsData[indexOf(orchestrationsData, existingData)] = combinedOrchestrationData;
    } else {
      combinedOrchestrationsData.push(data);
    }
  });

  return combinedOrchestrationsData;
};

export const convertFeatureActionsToBackend = (
  postProcessingActionsById: Dictionary<PostProcessingAction>,
  featureActions: PostProcessingActionCreated[],
  newCellsCreated: Record<string, NewCell>
) => {
  return map(featureActions, (action) => {
    const intermediateCellsInput = filter(postProcessingActionsById?.[action.actionId]?.inputs, {
      inputSource: InputSource.INTERMEDIATE_CELLS_FEATURES_PER_TARGET_STAIN,
    });

    if (!isEmpty(intermediateCellsInput)) {
      const newAction = cloneDeep(action);
      forEach(intermediateCellsInput, (input) => {
        if (input.inputType === InputType.SELECT) {
          const selectedValue = get(action, input.inputKey);
          if (newCellsCreated[selectedValue]) {
            set(newAction, input.inputKey, convertLogicalQuery(newCellsCreated[selectedValue].value));
          }
        } else if (input.inputType === InputType.MULTISELECT) {
          const selectedValues = get(action, input.inputKey);
          const newValue = map(selectedValues, (selectedOption) => {
            if (newCellsCreated[selectedOption]) {
              return convertLogicalQuery(newCellsCreated[selectedOption].value);
            }
            return selectedOption;
          });
          set(newAction, input.inputKey, newValue);
        }
      });

      return newAction;
    }

    return action;
  });
};

export const convertPostProcessingActionsToBackend = (
  postProcessingActionsById: Dictionary<PostProcessingAction>,
  postProcessingActions: PostProcessingActionCreated[]
) => {
  return map(postProcessingActions, (action) => {
    const logicalQueryInput = find(postProcessingActionsById?.[action.actionId]?.inputs, {
      inputType: InputType.LOGICAL_QUERY,
    });

    if (logicalQueryInput) {
      const newAction = cloneDeep(action);
      const convertedLogicalQuery = convertLogicalQuery(get(action, logicalQueryInput.inputKey));
      set(newAction, logicalQueryInput.inputKey, convertedLogicalQuery);
      return newAction;
    }

    return action;
  });
};

export const convertPostProcessingActionsFromBackend = (
  postProcessingActionsById: Dictionary<PostProcessingAction>,
  postProcessingActions: PostProcessingActionCreated[],
  getMappingFiltersMetadataForLogicalQuery: (
    stain: string,
    index: number,
    postProcessingActions: PostProcessingActionCreated[],
    actionInput: string,
    postProcessingActionsById: Dictionary<PostProcessingAction>
  ) => any,
  getOptionsByValue: (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actionInput: string,
    inputSourceDependentOn?: string
  ) => Dictionary<{ id: string | number; label: string }>
) => {
  return map(postProcessingActions, (action, index) => {
    const newAction = cloneDeep(action);

    const logicalQueryInput = find(postProcessingActionsById?.[action.actionId]?.inputs, {
      inputType: InputType.LOGICAL_QUERY,
    });

    const freeSoloInput = find(postProcessingActionsById?.[action.actionId]?.inputs, {
      freeSolo: true,
    });

    if (logicalQueryInput) {
      set(
        newAction,
        logicalQueryInput.inputKey,
        convertLogicalQueryFromBackend(
          get(action, logicalQueryInput.inputKey),
          getMappingFiltersMetadataForLogicalQuery(
            action?.stain,
            index,
            postProcessingActions,
            action.actionInput,
            postProcessingActionsById
          )
        )
      );
    }
    if (freeSoloInput) {
      const options = getOptionsByValue(
        freeSoloInput.inputSource,
        [action.stain],
        index,
        action.actionInput,
        get(action, freeSoloInput.inputSourceDependentOn)
      );
      // if the value is not in the options, it means it is a new option value
      if (isEmpty(options) || !options[get(action, freeSoloInput.inputKey)]) {
        if (get(action, freeSoloInput.inputKey)) set(newAction, 'newOptionValue', get(action, freeSoloInput.inputKey));

        const attributeToMapInput = find(postProcessingActionsById?.[action.actionId]?.inputs, {
          inputKey: 'action_params.attribute_to_map',
        });

        if (attributeToMapInput && get(action, attributeToMapInput.inputKey)) {
          set(newAction, 'newOptionValueInput', get(action, attributeToMapInput.inputKey));
        }
      } else {
        delete newAction.newOptionValue;
        delete newAction.newOptionValueInput;
      }
    } else {
      delete newAction.newOptionValue;
      delete newAction.newOptionValueInput;
    }

    return newAction;
  });
};

const getNewCellIdIfAlreadyCreated = (newCellsCreatedToSearch: Record<string, NewCell>, value: AutomaticCondition) => {
  const newCellKey = findKey(newCellsCreatedToSearch, (newCellCreated) => isEqual(newCellCreated.value, value));

  if (newCellKey) {
    newCellsCreatedToSearch[newCellKey].name = getNameByValue(value);
  }

  return newCellKey;
};

const getNewCellCreatedIdAndAddIfNeeded = (
  newCellsCreatedToAdd: Record<string, NewCell>,
  cellValue: any,
  getMappingFiltersMetadataForLogicalQuery: (stain: string) => MappingFilterMetadata[],
  stain: string
) => {
  const newCellValue = convertLogicalQueryFromBackend(cellValue, getMappingFiltersMetadataForLogicalQuery(stain));

  let newCellId = getNewCellIdIfAlreadyCreated(newCellsCreatedToAdd, newCellValue);

  if (!newCellId) {
    newCellId = uuidv4();
    newCellsCreatedToAdd[newCellId] = {
      name: getNameByValue(newCellValue),
      stain: stain,
      value: newCellValue,
    };
  }

  return newCellId;
};

export const convertFeatureActionsFromBackend = (
  postProcessingActionsById: Dictionary<PostProcessingAction>,
  featureActions: PostProcessingActionCreated[],
  getMappingFiltersMetadataForLogicalQuery: (stain: string) => MappingFilterMetadata[]
) => {
  let newCellsCreated: Record<string, NewCell> = {};

  const convertedFeatureActions = map(featureActions, (action) => {
    const intermediateCellsInput = filter(postProcessingActionsById?.[action.actionId]?.inputs, {
      inputSource: InputSource.INTERMEDIATE_CELLS_FEATURES_PER_TARGET_STAIN,
    });

    if (!isEmpty(intermediateCellsInput)) {
      const newAction = cloneDeep(action);
      forEach(intermediateCellsInput, (input) => {
        const value = get(action, input.inputKey);
        const betweenStainsInput = isFeatureBetweenStains(action);
        const isTargetInput = input.inputKey === targetCellClassesInputKey;
        // understand why first is used here and not going over all stains
        const stain: string = betweenStainsInput
          ? isTargetInput
            ? first(get(action, targetStainsInputKey))
            : first(get(action, neighborStainsInputKey))
          : first(get(action, 'stains'));

        if (input.inputType === InputType.SELECT) {
          // if value is new cell
          if (isObject(value)) {
            const newCellId = getNewCellCreatedIdAndAddIfNeeded(
              newCellsCreated,
              value,
              getMappingFiltersMetadataForLogicalQuery,
              stain
            );

            set(newAction, input.inputKey, newCellId);
          }
        } else if (input.inputType === InputType.MULTISELECT) {
          const newValues = map(value, (selectedOption) => {
            if (isObject(selectedOption)) {
              const newCellId = getNewCellCreatedIdAndAddIfNeeded(
                newCellsCreated,
                selectedOption,
                getMappingFiltersMetadataForLogicalQuery,
                stain
              );

              return newCellId;
            }

            return selectedOption;
          });
          set(newAction, input.inputKey, newValues);
        }
      });

      return newAction;
    }

    return action;
  });

  return {
    newCellsCreated,
    convertedFeatureActions,
  };
};

export const getSelectedAssignmentDetails = (
  assignmentInput: Input,
  slideIdStainingType: string,
  annotationAssignmentsByStain: {
    [stainType: string]: AnnotationAssignment[];
  }
): SelectedAssignmentDetails => {
  return {
    id: uuidv4(),
    modelType: assignmentInput.modelType,
    assignment: find(annotationAssignmentsByStain[slideIdStainingType], {
      annotationAssignmentId: assignmentInput.assignmentId,
    }),
    useClassesFromIgnore: isEmpty(assignmentInput.classesToUse) ? true : false,
    classToIgnore: assignmentInput.classesToIgnore,
    classToUse: assignmentInput.classesToUse,
  };
};

export interface RegistrationDetailsWithStains extends RegistrationDetails {
  sourceStainingType: string;
  targetStainingType: string;
}

export const getSlidesRegistrationWithStains = (
  slides: SlideInferenceResults[],
  selectedSlideRegistrationsByOrchestration: SlideRegistrationDetailsByOrchestration
): RegistrationDetailsWithStains[] => {
  const slidesById = keyBy(slides, 'slideId');
  const flatRegistrations = flatMap(values(selectedSlideRegistrationsByOrchestration));

  const registrationDetailsWithStains: RegistrationDetailsWithStains[] = map(flatRegistrations, (registration) => {
    return {
      ...registration,
      sourceStainingType: slidesById[registration.sourceSlideId].stainingType,
      targetStainingType: slidesById[registration.targetSlideId].stainingType,
    };
  });

  return registrationDetailsWithStains;
};

const validateSlideRegistrations = (
  slideId: string,
  neighborStains: string[],
  selectedTargetStainsRegistrations: RegistrationDetailsWithStains[]
): boolean => {
  const validated = every(neighborStains, (neighborStain) => {
    const doesSlideHaveRegistrationWithNeighborStain = find(selectedTargetStainsRegistrations, (registration) => {
      return (
        (registration.sourceSlideId === slideId && registration.targetStainingType === neighborStain) ||
        (registration.targetSlideId === slideId && registration.sourceStainingType === neighborStain)
      );
    });
    return doesSlideHaveRegistrationWithNeighborStain;
  });

  return validated;
};

const validateTargetStainRegistrations = (
  neighborStains: string[],
  slidesOfTargetStain: SlideInferenceResults[],
  selectedTargetStainsRegistrations: RegistrationDetailsWithStains[]
): boolean => {
  const slidesOfSelectedTargetStains: SlideInferenceResults[] = slidesOfTargetStain;
  const validated = every(slidesOfSelectedTargetStains, (slide) => {
    return validateSlideRegistrations(slide.slideId, neighborStains, selectedTargetStainsRegistrations);
  });

  return validated;
};

export const getAdditionalInputError = (
  inputValue: any,
  input: PostProcessingActionInput,
  action: PostProcessingActionCreated,
  slides: SlideInferenceResults[],
  selectedRegistrationsWithStains: RegistrationDetailsWithStains[]
) => {
  const specificInputError = getSpecificInputError(inputValue, input, action);
  if (specificInputError) return specificInputError;

  if (isFeatureBetweenStains(action) && input.inputKey === neighborStainsInputKey) {
    const selectedTargetStains: string[] = get(action, targetStainsInputKey);
    const selectedTargetStainsRegistrations = filter(
      selectedRegistrationsWithStains,
      (registration: RegistrationDetailsWithStains) => {
        const isSourceStainingType = includes(selectedTargetStains, registration.sourceStainingType);
        const isTargetStainingType = includes(selectedTargetStains, registration.targetStainingType);
        return isSourceStainingType || isTargetStainingType;
      }
    );

    if (size(slides) > 0 && isEmpty(selectedRegistrationsWithStains)) {
      return 'Must select registrations for this feature';
    }

    const slidesByStain = groupBy(slides, 'stainingType');
    const validated = every(selectedTargetStains, (targetStain) =>
      validateTargetStainRegistrations(inputValue, slidesByStain[targetStain], selectedTargetStainsRegistrations)
    );

    if (!validated) {
      return 'Missing registration. Must select for each slide with target stain, a registration with the neighbor stain';
    }
  }

  return '';
};
