import { CircularProgress, Collapse, Grid, MenuItem, Select, Skeleton, TextField, Typography } from '@mui/material';
import { QueryFunctionContext, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { getAnnotationAssignments } from 'api/annotationAssignments';
import { createJobPreset, getJobPresets, updateJobPreset } from 'api/jobPreset';
import { getJob } from 'api/jobs';
import { getModelTypeOptions } from 'api/modelTypes';
import { getPipRunNames } from 'api/pipsRunNames';
import { getArtifactsInference, runCalculateFeatures } from 'api/platform';
import { getSlidesDataForArtifactsResults } from 'api/study';
import PresetSection from 'components/atoms/PresetSection';
import PresetActionButtons from 'components/atoms/PresetSection/PresetActionButtons';
import SavePreset from 'components/atoms/PresetSection/SavePreset';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import PageHeader from 'components/PageHeader';
import { PlatformStepper } from 'components/StudyDashboard/ProceduresPage/Actions/PlatformStepper';
import { getAssignmentTodosClassNameOptions } from 'components/StudyDashboard/ProceduresPage/Actions/utils';
import { AnnotationAssignment } from 'interfaces/annotation';
import { AutomaticCondition } from 'interfaces/automaticCondition';
import {
  ClassificationBinningParams,
  InferenceModelData,
  Input,
  Inputs,
  MapValuesToBins,
  ModelInference,
  OrchestrationData,
  OrchestrationInference,
  ResultsInput,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import GridBasedCalculationParams from 'interfaces/girdBasedCalculationParams';
import {
  AreaConfig,
  BinaryClassifierConfig,
  CellAttributesTableConfig,
  CellConfig,
  CellSegmentationConfig,
  ClassificationConfig,
  ClassificationIntensityConfig,
  DefectConfig,
  NucleiConfig,
  TlsConfig,
} from 'interfaces/inferenceArtifacts';
import { CalculateFeaturesJob, CalculateFeaturesParams, CellTypingJob, ThresholdJob } from 'interfaces/job';
import { JobPreset } from 'interfaces/jobPreset';
import { CellRules, Thresholds } from 'interfaces/jobs/multiplex/cellTypingParams';
import { PipRunName } from 'interfaces/jobs/pipsRunNames';
import {
  ActionInput,
  Input as PostProcessingInput,
  InputType,
  PostProcessingActionCreated,
} from 'interfaces/postProcessingAction';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { VisualizationCreated } from 'interfaces/visualization';
import {
  cloneDeep,
  concat,
  Dictionary,
  difference,
  every,
  filter,
  find,
  findIndex,
  flatMap,
  flattenDeep,
  forEach,
  get,
  groupBy,
  includes,
  isEmpty,
  isNumber,
  join,
  keyBy,
  keys,
  map,
  mapValues,
  pick,
  pickBy,
  pull,
  pullAt,
  range,
  reduce,
  set,
  setWith,
  size,
  some,
  times,
  toNumber,
  uniq,
  values,
} from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { stringify } from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrayParam } from 'use-query-params';
import { humanize, uuidv4 } from 'utils/helpers';
import { useClassificationModelOptions } from 'utils/queryHooks/uiConstantsHooks';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import {
  modelTypeArea,
  modelTypeBinaryClassifier,
  modelTypeCell,
  modelTypeClassification,
  modelTypeDefect,
  modelTypeNucleiSegmentation,
  modelTypesByApiModelValue,
  modelTypeTls,
  modelTypeTsm,
} from '../Jobs/inferenceFieldsOptions';
import { FeatureActions } from './FeaturesStep';
import GridBasedCalculationParamsStep, { isGridBasedCalculationParamsValid } from './GridBasedCalculationParamsStep';
import InferenceModelsForSlides from './InferenceModelsForSlides';
import {
  getFlowClassNameTypesByOrchestrations,
  getModelsTypeByModelInferences,
  getSidesWithoutSomeModels,
  getSlidesModelsData,
} from './InferenceModelsForSlides/utils';
import { PostProcessingActions } from './PostProcessingActions';
import { getRelevantSelectedStainsForInput } from './PostProcessingActions/PostProcessingAction';
import usePostProcessingActions, {
  allCellsOption,
  useActionsQuery,
} from './PostProcessingActions/usePostProcessingActions';
import { validateInput } from './PostProcessingActions/utils';
import PresetStainModal, { Action, StainsActions } from './PresetStainModal';
import {
  combineOrchestrationMetadata,
  convertFeatureActionsFromBackend,
  convertFeatureActionsToBackend,
  convertPostProcessingActionsFromBackend,
  convertPostProcessingActionsToBackend,
  getAdditionalInputError,
  getModelId,
  getSelectedAssignmentDetails,
  getSlidesRegistrationWithStains,
} from './utils';
import { VisualizationStep } from './VisualizationStep';

const SNACK_BAR_KEY_CALCULATE_FEATURES = 'CALCULATE_FEATURES';
const SNACK_BAR_KEY_SAVE_PRESET = 'SAVE_PRESET';
const SNACK_BAR_KEY_UPDATE_PRESET = 'UPDATE_PRESET';

const basicInferenceArtifactConfigs = [
  AreaConfig,
  CellConfig,
  DefectConfig,
  TlsConfig,
  ClassificationIntensityConfig,
  ClassificationConfig,
  NucleiConfig,
];

const postprocessedArtifactsConfig = [CellAttributesTableConfig];

export interface OrchestrationBySlideByType {
  [slideId: string]: {
    [modelType: string]: {
      orchestration: OrchestrationInference;
      model: InferenceModelData;
    };
  };
}

export interface OrchestrationBySlideByFlowClassName {
  [slideId: string]: {
    [flowClassName: string]: {
      orchestration: OrchestrationInference;
    };
  };
}

export interface SelectedAssignmentDetails {
  id: string;
  modelType: string;
  assignment: AnnotationAssignment;
  useClassesFromIgnore?: boolean;
  classToIgnore?: string[];
  classToUse?: string[];
}

export interface AssignmentByStain {
  [stain: string]: SelectedAssignmentDetails[];
}

export interface RegistrationDetails {
  sourceSlideId: string;
  targetSlideId: string;
  artifactUrl: string;
}

export interface SlideRegistrationDetailsByOrchestration {
  [orchestrationId: string]: RegistrationDetails[];
}

export interface NewCell {
  name: string;
  value: AutomaticCondition;
  stain: string;
}

export interface ModelClassificationById {
  [modelId: string]: {
    readAs: string;
    numBins?: number;
    mapValuesToBins?: MapValuesToBins[];
  };
}

export interface ClassConfigs {
  [stain: string]: {
    [modelKey: string]: string[] | number[];
  };
}

export interface OrchestrationMetadata {
  [stain: string]: {
    [modelKey: string]: OrchestrationData[];
  };
}

export enum CalculateFeaturesStep {
  SelectPipsName = 0,
  SelectModels = 1,
  Presets = 2,
  Postprocessing = 3,
  Features = 4,
  GridBasedCalculation = 5,
  Visualizations = 6,
}

const defaultPipsRunName = 'generic_pips_run';

const CalculateFeatures: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const [selectedPipsRunName, setSelectedPipsRunName] = useState<string>(defaultPipsRunName);
  const [postProcessingActions, setPostProcessingActions] = useState<PostProcessingActionCreated[]>([]);
  const [featureActions, setFeatureActions] = useState<PostProcessingActionCreated[]>([]);
  const [gridBasedCalculationParams, setGridBasedCalculationParams] = useState<
    Record<string, GridBasedCalculationParams>
  >({}); // key is a stain type id
  const [newCellsCreated, setNewCellsCreated] = useState<Record<string, NewCell>>({});
  const [selectedOrchestrations, setSelectedOrchestrations] = useState<OrchestrationBySlideByType>({});
  const [selectedPostprocessedOrchestrations, setSelectedPostprocessedOrchestrations] =
    useState<OrchestrationBySlideByFlowClassName>({});
  const [selectedAssignments, setSelectedAssignments] = useState<AssignmentByStain>({});
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  const [stains, setStains] = useState<string[]>([]);
  const [classConfigWithSpecialClasses, setClassConfigWithSpecialClasses] = useState<ClassConfigs>({});
  const [orchestrationMetadata, setOrchestrationMetadata] = useState<OrchestrationMetadata>({});
  const [visualizations, setVisualizations] = useState<VisualizationCreated[]>([]);
  const [postProcessingClasses, setPostProcessingClasses] = useState<ClassConfigs>({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isStepFailed, setIsStepFailed] = useState<Record<number, boolean>>({});
  const [isAddingPreset, setIsAddingPreset] = useState(false);
  const [currentPreset, setCurrentPreset] = useState<JobPreset>({} as JobPreset);
  const [presetStainModalOpen, setPresetStainModalOpen] = useState(false);
  const [isRebuildParametersSet, setIsRebuildParametersSet] = useState(false);
  const [isRebuildInferenceResultsSet, setIsRebuildInferenceResultsSet] = useState(false);
  const [modelClassificationByModelId, setModelClassificationByModelId] = useState<ModelClassificationById>({});
  const [selectedSlideRegistrations, setSelectedSlideRegistration] = useState<
    SlideRegistrationDetailsByOrchestration | undefined
  >(undefined);

  // General configuration for multiplex jobs
  const [selectedThresholdJobId, setSelectedThresholdJobId] = useState<string | null>(null);
  const [selectedCellTypingJobId, setSelectedCellTypingJobId] = useState<string | null>(null);
  const [thresholds, setThresholds] = useState<Thresholds>(null);
  const [cellRules, setCellRules] = useState<CellRules>(null);
  const [knnArtifactUrl, setKnnArtifactUrl] = useState<string>(null);
  const [knnFileType, setKnnFileType] = useState<string>(null);

  const onThresholdJobSelect = (newThresholdJob: ThresholdJob) => {
    const newThresholds = newThresholdJob?.results?.perMarkerThresholds;
    setThresholds(newThresholds);
  };

  const onCellRulesChange = (newCellRules: CellRules) => {
    setCellRules(newCellRules);
  };

  const onCellTypingJobSelect = (cellTypingJob: CellTypingJob) => {
    const newKnn = cellTypingJob?.results?.slideInferenceMultiplexMarkersProbabilities;
    setKnnArtifactUrl(newKnn?.artifactUrl);
    setKnnFileType(newKnn?.fileType);
  };

  const [jobSummaryData, setJobSummaryData] = useState<{
    name: string;
    description: string;
  }>({
    name: null,
    description: null,
  });

  const { data: modelTypes, isError: modelTypesError } = useQuery(['modelTypes'], () => getModelTypeOptions(), {
    retry: false,
  });
  const modelTypesByType = keyBy(modelTypes, 'type');

  const gridBasedFeaturesStains = useMemo(
    () =>
      uniq(
        flatMap(
          filter(featureActions, (action) => !isEmpty(action?.action_params?.grid_based_params?.reduction_type)),
          'stains'
        )
      ),
    [featureActions]
  );

  useEffect(() => {
    // Omit stains that not longer have grid based features
    setGridBasedCalculationParams((previousParams) => {
      return pickBy(previousParams, (_, stain) => includes(gridBasedFeaturesStains, stain));
    });
  }, [gridBasedFeaturesStains]);

  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();
  const { classificationModelOptions } = useClassificationModelOptions();

  const selectedClassificationModels = map(values(modelClassificationByModelId), (item) => item.readAs);
  const selectedClassificationModelsOptions = filter(classificationModelOptions, (option) =>
    some(selectedClassificationModels, (selectedClassificationModel) => selectedClassificationModel === option.id)
  );

  const { getOptions, getOptionsByValue, getOutputNamesForAllStains, getMappingFiltersMetadataForLogicalQuery } =
    usePostProcessingActions(stains, classConfigWithSpecialClasses, orchestrationMetadata, newCellsCreated);

  const { postProcessingActionsById } = useActionsQuery();

  const setCalculateFeatureParams = (params: {
    postprocessing: PostProcessingActionCreated[];
    features: PostProcessingActionCreated[];
    visualizations: VisualizationCreated[];
    gridBasedCalculationParams: { [key: string]: GridBasedCalculationParams };
    registrations: RegistrationDetails[];
  }) => {
    if (!isEmpty(params.postprocessing)) {
      setPostProcessingActions(
        convertPostProcessingActionsFromBackend(
          postProcessingActionsById,
          params.postprocessing,
          getMappingFiltersMetadataForLogicalQuery,
          (inputSource, selectedStains, index, actionInput, inputSourceDependentOn) =>
            getOptionsByValue(
              inputSource,
              selectedStains,
              index,
              params.postprocessing,
              actionInput,
              inputSourceDependentOn
            )
        )
      );
      setPostProcessingClasses(getOutputNamesForAllStains(params.postprocessing.length, params.postprocessing));
    }

    if (!isEmpty(params.features)) {
      const { newCellsCreated: newCells, convertedFeatureActions } = convertFeatureActionsFromBackend(
        postProcessingActionsById,
        params.features,
        (stain) =>
          getMappingFiltersMetadataForLogicalQuery(
            stain,
            postProcessingActions.length,
            postProcessingActions,
            ActionInput.FEATURE_FAMILY,
            postProcessingActionsById
          )
      );

      setFeatureActions(convertedFeatureActions);
      setNewCellsCreated(newCells);
    }

    if (!isEmpty(params.gridBasedCalculationParams)) {
      setGridBasedCalculationParams(params.gridBasedCalculationParams);
    }

    if (!isEmpty(params.visualizations))
      setVisualizations(
        map(params.visualizations, (visualization) => ({ ...visualization, newVisualizationId: uuidv4() }))
      );
  };

  const {
    data: job,
    isFetched: isJobFetched,
    isError: isJobError,
  } = useQuery({
    queryKey: ['job', location.state?.jobId],
    queryFn: ({ signal }) => getJob(location.state?.jobId, signal) as Promise<CalculateFeaturesJob>,
    enabled: !isEmpty(location.state?.jobId),
    retry: false,
  });

  const doesSpecificModelExist = (stain: string, specificModelType: string) => {
    return some(selectedOrchestrations, (selectedOrchestration, slideId) => {
      return some(selectedOrchestration, (orchestrationData, modelType) => {
        return modelType === specificModelType && slidesById[slideId]?.stainingType === stain;
      });
    });
  };

  // Add defects area type to the area class config if defect model is selected
  // Add allCellsOption to the cell class config if cell model is selected
  const getClassConfigWithSpecialClasses = (currentClassConfig: ClassConfigs) => {
    const newClassConfig = { ...currentClassConfig };

    forEach(keys(currentClassConfig), (stain) => {
      set(
        newClassConfig,
        [stain, modelTypeArea.value],
        concat(
          (newClassConfig?.[stain]?.[modelTypeArea.value] as string[]) || [],
          (newClassConfig?.[stain]?.[modelTypeTls.value] as string[]) || []
        )
      );

      if (doesSpecificModelExist(stain, modelTypeDefect.apiModelValue)) {
        const areaClasses = (newClassConfig?.[stain]?.[modelTypeArea.value] as string[]) || [];
        const defectsClasses = (newClassConfig?.[stain]?.[modelTypeDefect.value] as string[]) || [];
        set(
          newClassConfig,
          [stain, modelTypeArea.value],
          concat(areaClasses, defectsClasses, 'defects', 'defects_raw')
        );
      }

      if (doesSpecificModelExist(stain, modelTypeCell.apiModelValue)) {
        const cellClasses = (newClassConfig?.[stain]?.[modelTypeCell.value] as string[]) || [];
        set(newClassConfig, [stain, modelTypeCell.value], concat(cellClasses, allCellsOption.id));
      }

      // remove backgroundClassesToIgnore from the class config
      forEach(modelTypes, (modelType) => {
        if (newClassConfig[stain]?.[modelType.id] && modelType.backgroundClassesToIgnore) {
          set(
            newClassConfig,
            [stain, modelType.id],
            difference(newClassConfig[stain][modelType.id] as string[], modelType.backgroundClassesToIgnore)
          );
        }
      });
    });

    return newClassConfig;
  };

  const setClassConfigFromSelectedResults = (
    slidesById: Dictionary<SlideInferenceResults>,
    selectedInference: OrchestrationBySlideByType,
    selectedPostprocessedArtifacts: OrchestrationBySlideByFlowClassName,
    modelClassification: ModelClassificationById,
    selectedAssignmentsByStain: AssignmentByStain
  ) => {
    const newClassConfig: ClassConfigs = {};
    const newOrchestrationMetadata: OrchestrationMetadata = {};

    forEach(selectedInference, (orchestrationByModel, slideId) => {
      forEach(orchestrationByModel, (orchestrationData, modelType) => {
        let classes: string[] | number[] = orchestrationData?.model?.classes;
        const modelId = orchestrationData?.model?.modelId ?? getModelId(orchestrationData?.model?.modelUrl);
        const currentModelClassification = modelClassification?.[modelId];
        const currentModelType =
          currentModelClassification?.readAs ?? modelTypesByApiModelValue[modelType]?.value ?? modelType;

        // classification intensity model classes
        if (
          currentModelClassification &&
          find(classificationModelOptions, {
            id: currentModelClassification?.readAs,
          })?.needBinningParams &&
          (currentModelClassification?.numBins || !isEmpty(currentModelClassification?.mapValuesToBins))
        ) {
          classes = currentModelClassification?.mapValuesToBins
            ? map(currentModelClassification.mapValuesToBins, (mapValuesToBin) => toNumber(mapValuesToBin.bin))
            : map(range(1, currentModelClassification?.numBins + 1), (bin) => toNumber(bin));
        }

        const slideStainingType = slidesById[slideId].stainingType;
        // add all cells class to cells model if binary classifier model is selected
        if (currentModelType === modelTypeBinaryClassifier.value) {
          newClassConfig[slideStainingType] = {
            ...newClassConfig[slideStainingType],
            [modelTypeCell.value]: [allCellsOption.id],
          };
        }

        newClassConfig[slideStainingType] = {
          ...newClassConfig[slideStainingType],
          [currentModelType]: classes,
        };

        const flowClassName = orchestrationData?.orchestration?.params?.flowClassName;
        const data = orchestrationData?.orchestration?.params?.data;
        if (!isEmpty(data) && Boolean(flowClassName)) {
          newOrchestrationMetadata[slidesById[slideId].stainingType] = {
            ...newOrchestrationMetadata[slidesById[slideId].stainingType],
            [flowClassName]: data,
          };
        }
      });
    });

    forEach(selectedPostprocessedArtifacts, (orchestrationByFlowClassName, slideId) => {
      forEach(orchestrationByFlowClassName, (orchestrationData, flowClassName) => {
        if (!isEmpty(orchestrationData?.orchestration?.params?.data)) {
          // Because the orchestration data is under orchestration, we need to check if it already exists in newOrchestrationMetadata and combined the data
          const newData = combineOrchestrationMetadata(
            get(newOrchestrationMetadata, [slidesById[slideId].stainingType, flowClassName], []),
            orchestrationData.orchestration.params.data
          );
          newOrchestrationMetadata[slidesById[slideId].stainingType] = {
            ...newOrchestrationMetadata[slidesById[slideId].stainingType],
            [flowClassName]: newData,
          };
        }
      });
    });

    forEach(selectedAssignmentsByStain, (assignments, stain) => {
      forEach(assignments, (assignment) => {
        const assignmentClasses = getAssignmentTodosClassNameOptions(assignment?.assignment?.todos);
        const classes = assignment.useClassesFromIgnore
          ? difference(assignmentClasses, assignment.classToIgnore)
          : assignment.classToUse;

        const modelTypeId = modelTypesByApiModelValue[assignment.modelType]?.value ?? assignment.modelType;
        set(newClassConfig, [stain, modelTypeId], uniq(concat(newClassConfig[stain]?.[modelTypeId] ?? [], classes)));
      });
    });

    setStains(uniq(concat(keys(newClassConfig), keys(newOrchestrationMetadata))));
    setOrchestrationMetadata(newOrchestrationMetadata);
    setClassConfigWithSpecialClasses(getClassConfigWithSpecialClasses(newClassConfig));
  };

  // Set the calculate feature params from the job params after classConfigWithSpecialClasses is set
  // setCalculateFeatureParams depends on actions from usePostProcessingActions, which depends on classConfigWithSpecialClasses
  useEffect(() => {
    if (
      !isEmpty(location.state?.jobId) &&
      !isRebuildParametersSet &&
      isJobFetched &&
      !isEmpty(classConfigWithSpecialClasses)
    ) {
      setCalculateFeatureParams(job?.params as CalculateFeaturesParams);
      setIsRebuildParametersSet(true);
    }
  }, [JSON.stringify(classConfigWithSpecialClasses)]);

  const setModelResults = (
    params: { inputs?: Inputs },
    allInferenceResults: ModelInference[],
    allPostprocessedArtifacts: OrchestrationInference[],
    annotationAssignmentsByStain: {
      [stainType: string]: AnnotationAssignment[];
    }
  ) => {
    const slidesById = keyBy(slides, 'slideId');
    const selectedInference: OrchestrationBySlideByType = {};
    const selectedPostprocessedArtifacts: OrchestrationBySlideByFlowClassName = {};
    const selectedModelClassification: ModelClassificationById = {};
    const selectedAssignmentDetails: Record<string, Record<string, SelectedAssignmentDetails>> = {};

    forEach(params.inputs, (results, caseId) => {
      forEach(results, (slideResults, slideId) => {
        forEach(slideResults, (result) => {
          if (result.input.artifactUrl) {
            if (result.input.flowClassName) {
              const currentOrchestration = find(allPostprocessedArtifacts, (orchestration) => {
                return orchestration.orchestrationResultArtifactUrlPattern === result.input.artifactUrl;
              });

              if (!isEmpty(currentOrchestration)) {
                selectedPostprocessedArtifacts[slideId] = {
                  ...selectedPostprocessedArtifacts[slideId],
                  [result.input.flowClassName]: {
                    orchestration: currentOrchestration,
                  },
                };
              }
            } else {
              const resultArtifact = result.input.artifactUrl;

              const currentModel = find(allInferenceResults, (model) => {
                return some(model.orchestrations, (orchestration) => {
                  return orchestration.orchestrationResultArtifactUrlPattern === resultArtifact;
                });
              });

              if (!isEmpty(currentModel)) {
                const currentOrchestration = find(currentModel.orchestrations, (orchestration) => {
                  return orchestration.orchestrationResultArtifactUrlPattern === resultArtifact;
                });

                selectedInference[slideId] = {
                  ...selectedInference[slideId],
                  [result?.input?.readAs ?? currentModel.modelType]: {
                    orchestration: currentOrchestration,
                    model: currentModel,
                  },
                };

                // if model classification, add classification to the modelClassificationById
                if (result?.input?.readAs && find(classificationModelOptions, { id: result.input.readAs })) {
                  const modelId = currentModel?.modelId ?? getModelId(currentModel?.modelUrl);
                  selectedModelClassification[modelId] = {
                    readAs: result.input.readAs,
                    numBins: result.input.binning?.numBins,
                    mapValuesToBins: result.input.binning?.mapValuesToBins,
                  };
                }
              }
            }
          } else if (result.input.assignmentId) {
            setWith(
              selectedAssignmentDetails,
              [slidesById[slideId].stainingType, result.input.assignmentId],
              getSelectedAssignmentDetails(
                result.input,
                slidesById[slideId].stainingType,
                annotationAssignmentsByStain
              ),
              Object
            );
          }

          if (result.override?.assignmentId) {
            setWith(
              selectedAssignmentDetails,
              [slidesById[slideId].stainingType, result.override.assignmentId],
              getSelectedAssignmentDetails(
                result.override,
                slidesById[slideId].stainingType,
                annotationAssignmentsByStain
              ),
              Object
            );
          }
        });
      });
    });

    const selectedAssignmentDetailsByStain: AssignmentByStain = mapValues(selectedAssignmentDetails, (stain) =>
      values(stain)
    );

    setModelClassificationByModelId(selectedModelClassification);
    setSelectedOrchestrations(selectedInference);
    setSelectedPostprocessedOrchestrations(selectedPostprocessedArtifacts);
    setSelectedAssignments(selectedAssignmentDetailsByStain);
    setClassConfigFromSelectedResults(
      keyBy(slides, 'slideId'),
      selectedInference,
      selectedPostprocessedArtifacts,
      selectedModelClassification,
      selectedAssignmentDetailsByStain
    );
  };

  const studyId = isEmpty(job) ? location.state?.studyId : job?.studyId;
  const encodedQuery = isEmpty(job)
    ? location.state?.encodedQuery
    : encodeQueryParamsUsingSchema(
        { slideIdsToInclude: flattenDeep(values(job?.manifest)) },
        { slideIdsToInclude: ArrayParam },
        { arrayFormat: 'repeat' }
      );

  const enableInferenceArtifactsQuery =
    (isEmpty(location.state?.jobId) && !isEmpty(studyId)) || (!isEmpty(location.state?.jobId) && isJobFetched);

  const {
    data: slides,
    isFetched: isSlidesFetched,
    isError: isSlidesError,
  } = useQuery({
    queryKey: ['slidesDataForArtifactsResults', { studyId: studyId, stringParams: encodedQuery }],
    queryFn: ({ signal }) => getSlidesDataForArtifactsResults(studyId, encodedQuery, signal),
    retry: false,
    enabled: Boolean(studyId),
  });

  const getAdditionalInputErrorMessage = (
    inputValue: any,
    input: PostProcessingInput,
    action: PostProcessingActionCreated
  ): string => {
    const registrationsWithStains = getSlidesRegistrationWithStains(slides, selectedSlideRegistrations);
    return getAdditionalInputError(inputValue, input, action, slides, registrationsWithStains);
  };

  const inferenceArtifactConfigs = includes(map(slides, 'stainingType'), MULTIPLEX_STAIN_ID)
    ? [...basicInferenceArtifactConfigs, BinaryClassifierConfig, CellSegmentationConfig]
    : basicInferenceArtifactConfigs;

  const inferenceArtifactsQueries = useQueries({
    queries: times(size(inferenceArtifactConfigs), (index) => {
      const inferenceArtifactsParams = {
        studyId,
        caseParams: encodedQuery,
        configParams: inferenceArtifactConfigs[index],
      };
      return {
        queryKey: ['artifactsInference', inferenceArtifactsParams],
        queryFn: ({ signal }: QueryFunctionContext) => getArtifactsInference(inferenceArtifactsParams, signal),
        enabled: enableInferenceArtifactsQuery,
        placeholderData: [],
        retry: false,
      };
    }),
  });

  const inferenceArtifacts = flatMap(inferenceArtifactsQueries, (query) => query.data);
  const isInferenceArtifactsLoading =
    !isEmpty(inferenceArtifactsQueries) && some(inferenceArtifactsQueries, 'isLoading');
  const isInferenceArtifactsFetched =
    !isEmpty(inferenceArtifactsQueries) && every(inferenceArtifactsQueries, 'isFetched');
  const isInferenceArtifactsFetching =
    !isEmpty(inferenceArtifactsQueries) && some(inferenceArtifactsQueries, 'isFetching');
  const isInferenceArtifactsError = !isEmpty(inferenceArtifactsQueries) && some(inferenceArtifactsQueries, 'isError');

  const postprocessedArtifactsQueries = useQueries({
    queries: times(size(postprocessedArtifactsConfig), (index) => {
      const inferenceArtifactsParams = {
        studyId,
        caseParams: encodedQuery,
        configParams: postprocessedArtifactsConfig[index],
      };
      return {
        queryKey: ['postprocessedArtifacts', inferenceArtifactsParams],
        queryFn: ({ signal }: QueryFunctionContext) => getArtifactsInference(inferenceArtifactsParams, signal),
        enabled: enableInferenceArtifactsQuery,
        placeholderData: [],
        retry: false,
      };
    }),
  });

  const postprocessedArtifacts = flatMap(postprocessedArtifactsQueries, (query) => query.data);
  const isPostprocessedArtifactsLoading =
    !isEmpty(postprocessedArtifactsQueries) && some(postprocessedArtifactsQueries, 'isLoading');
  const isPostprocessedArtifactsFetched =
    !isEmpty(postprocessedArtifactsQueries) && every(postprocessedArtifactsQueries, 'isFetched');
  const isPostprocessedArtifactsFetching =
    !isEmpty(postprocessedArtifactsQueries) && some(postprocessedArtifactsQueries, 'isFetching');
  const isPostprocessedArtifactsError =
    !isEmpty(postprocessedArtifactsQueries) && some(postprocessedArtifactsQueries, 'isError');

  const slideStainTypes = uniq(map(slides, 'stainingType'));

  const annotationAssignmentsQueries = useQueries({
    queries: map(slideStainTypes, (stainingType) => {
      const encodedFilters = `${encodedQuery}${encodedQuery ? '&' : ''}${stringify({ slideStainType: stainingType })}`;

      return {
        queryKey: ['annotationAssignments', encodedFilters],
        queryFn: () => getAnnotationAssignments(encodedFilters),
      };
    }),
  });

  const finishLoadingAnnotationAssignments =
    isSlidesFetched &&
    !isEmpty(annotationAssignmentsQueries) &&
    every(annotationAssignmentsQueries, (query) => query.isFetched);

  const annotationAssignmentsByStain = reduce(
    annotationAssignmentsQueries,
    (acc, query, index) => {
      const stainType = slideStainTypes[index];
      return {
        ...acc,
        [stainType]: query.data,
      };
    },
    {}
  );

  if (
    isInferenceArtifactsFetched &&
    isPostprocessedArtifactsFetched &&
    finishLoadingAnnotationAssignments &&
    isSlidesFetched &&
    !isRebuildInferenceResultsSet &&
    !isEmpty(job)
  ) {
    setJobSummaryData({
      name: job?.name,
      description: job?.description,
    });
    setModelResults(
      job.params as CalculateFeaturesParams,
      inferenceArtifacts,
      postprocessedArtifacts,
      annotationAssignmentsByStain
    );
    setIsRebuildInferenceResultsSet(true);
  }

  const { data: presets, isLoading: isLoadingPresets } = useQuery({
    queryKey: ['jobPresets', { steps: ['calculate_features'] }],
    queryFn: ({ signal }) => getJobPresets(['calculate_features'], signal),
  });

  const {
    data: pipRunNames,
    isLoading: isLoadingPipsRunNames,
    isError: isErrorPipRunNames,
  } = useQuery({
    queryKey: ['pipRunNames'],
    queryFn: () => getPipRunNames(),
  });

  const runCalculateFeaturesMutation = useMutation(runCalculateFeatures, {
    onError: () => {
      enqueueSnackbar('Error occurred, Calculate Features failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Calculate Features Started', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_CALCULATE_FEATURES);
    },
  });

  const createJobPresetMutation = useMutation(createJobPreset, {
    onError: () => {
      enqueueSnackbar('Error occurred, save job preset failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('job preset saved', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_SAVE_PRESET);
    },
  });

  const updateJobPresetMutation = useMutation(updateJobPreset, {
    onError: () => {
      enqueueSnackbar('Error occurred, update job preset failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('job preset updated', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_UPDATE_PRESET);
    },
  });

  const slidesById = keyBy(slides, 'slideId');

  const onSubmit = () => {
    const casesManifest: Record<string, string[]> = {};
    const inputsPerCasePerSlide: Inputs = {};
    const slidesByCase = groupBy(slides, 'caseId');
    const selectedAssignmentsValues = flatMap(values(selectedAssignments));

    forEach(slidesByCase, (slidesOfCase, caseId) => {
      const slideIdsToCheck = map(slidesOfCase, 'slideId');
      const slideIdsWithResults: string[] = [];

      forEach(slideIdsToCheck, (slideId) => {
        if (
          !isEmpty(selectedOrchestrations[slideId]) ||
          !isEmpty(selectedPostprocessedOrchestrations[slideId]) ||
          some(selectedAssignmentsValues, (selectedAssignment) =>
            includes(selectedAssignment.assignment?.slideIds, slideId)
          )
        ) {
          slideIdsWithResults.push(slideId);
        }
      });

      if (!isEmpty(slideIdsWithResults)) {
        casesManifest[caseId] = slideIdsWithResults;
        inputsPerCasePerSlide[caseId] = {};

        forEach(slideIdsWithResults, (slideId) => {
          let modelTsmArtifactUrl = '';
          const slideStain = find(slidesOfCase, { slideId: slideId })?.stainingType;

          inputsPerCasePerSlide[caseId][slideId] = [];

          // Add selected orchestrations
          forEach(selectedOrchestrations[slideId], (orchestrationData, modelType) => {
            const input: ResultsInput = {
              input: {
                artifactUrl: orchestrationData?.orchestration.orchestrationResultArtifactUrlPattern,
                modelType: modelType,
              },
            };

            if (modelTypesByType?.[modelType]?.backgroundClassesToIgnore) {
              input.input.classesToIgnore = modelTypesByType[modelType].backgroundClassesToIgnore;
            }

            if (
              includes(
                map(classificationModelOptions, (option) => option.id),
                modelType
              )
            ) {
              input.input.readAs = modelType;

              // if the model is classification intensity model, add binning params
              if (find(classificationModelOptions, { id: modelType })?.needBinningParams) {
                const modelId = orchestrationData?.model?.modelId ?? getModelId(orchestrationData?.model?.modelUrl);
                if (modelClassificationByModelId[modelId]?.numBins) {
                  input.input.binning = { numBins: modelClassificationByModelId[modelId].numBins };
                } else if (modelClassificationByModelId[modelId]?.mapValuesToBins) {
                  input.input.binning = { mapValuesToBins: modelClassificationByModelId[modelId].mapValuesToBins };
                }
              }
            }

            inputsPerCasePerSlide[caseId][slideId].push(input);

            // All inference results must have the same TSM URL.
            // Therefore, it doesn't matter which TSM URL is selected (from area, cell, etc.) since they are all identical.
            // Hence, I will use the first one I find.
            if (
              isEmpty(modelTsmArtifactUrl) &&
              (!isEmpty(orchestrationData?.orchestration?.orchestrationTsmArtifactUrlPattern) ||
                !isEmpty(orchestrationData?.orchestration?.tsmSlideOverrides?.[slideId]))
            ) {
              modelTsmArtifactUrl =
                orchestrationData?.orchestration?.tsmSlideOverrides?.[slideId] ??
                orchestrationData?.orchestration?.orchestrationTsmArtifactUrlPattern;
            }
          });

          // Add cell segmentation artifact url if binary classifier is selected
          if (slideStain === MULTIPLEX_STAIN_ID) {
            const selectedBinaryClassifierModelType =
              selectedOrchestrations[slideId][modelTypeBinaryClassifier.apiModelValue];
            const cellSegmentationArtifactUrl =
              selectedBinaryClassifierModelType?.orchestration?.params?.deps?.detectionResults;
            if (cellSegmentationArtifactUrl) {
              inputsPerCasePerSlide[caseId][slideId].push({
                input: {
                  artifactUrl: cellSegmentationArtifactUrl,
                  modelType: modelTypeNucleiSegmentation.apiModelValue,
                },
              });
            }
          }

          // Add tsm model artifact url
          if (!isEmpty(modelTsmArtifactUrl)) {
            inputsPerCasePerSlide[caseId][slideId].push({
              input: {
                artifactUrl: modelTsmArtifactUrl,
                modelType: modelTypeTsm.apiModelValue,
              },
            });
          }

          // Add selected postprocessed orchestrations
          forEach(selectedPostprocessedOrchestrations[slideId], (orchestrationData, flowClassName) => {
            if (orchestrationData?.orchestration?.params?.data) {
              inputsPerCasePerSlide[caseId][slideId].push({
                input: {
                  artifactUrl: orchestrationData.orchestration.orchestrationResultArtifactUrlPattern,
                  flowClassName: flowClassName,
                },
              });
            }
          });

          // Add selected assignments
          forEach(selectedAssignmentsValues, (selectedAssignment) => {
            if (includes(selectedAssignment.assignment?.slideIds, slideId)) {
              const assignmentInput: Input = {
                assignmentId: selectedAssignment.assignment?.annotationAssignmentId,
                modelType: selectedAssignment.modelType,
              };

              if (selectedAssignment.useClassesFromIgnore) {
                assignmentInput.classesToIgnore = selectedAssignment.classToIgnore;
              } else {
                assignmentInput.classesToUse = selectedAssignment.classToUse;
              }

              const inputByModelTypeIndex = findIndex(
                inputsPerCasePerSlide[caseId][slideId],
                (input) => input.input.modelType === assignmentInput.modelType
              );

              if (inputByModelTypeIndex !== -1) {
                inputsPerCasePerSlide[caseId][slideId][inputByModelTypeIndex].override = assignmentInput;
              } else {
                inputsPerCasePerSlide[caseId][slideId].push({ input: assignmentInput });
              }
            }
          });
        });
      }
    });

    runCalculateFeaturesMutation.mutate({
      jobName: jobSummaryData.name,
      jobDescription: jobSummaryData.description,
      configParams: {
        manifest: casesManifest,
        inputs: inputsPerCasePerSlide,
        registrations: flatMap(values(selectedSlideRegistrations)),
        postprocessing: convertPostProcessingActionsToBackend(postProcessingActionsById, postProcessingActions),
        features: convertFeatureActionsToBackend(postProcessingActionsById, featureActions, newCellsCreated),
        gridBasedCalculationParams,
        visualizations,
        studyId,
        pipsStudyInputs: {
          perMarkerThresholds: thresholds,
          cellRules,
          knnClassifier: {
            artifactUrl: knnArtifactUrl,
            fileExtension: knnFileType,
          },
        },
      },
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Calculate Features to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_CALCULATE_FEATURES,
      autoHideDuration: null,
    });
  };

  const relevantModelTypes = uniq([
    ...getModelsTypeByModelInferences(inferenceArtifacts), // all the models (area, cell, nucleai, etc.)
    ...getFlowClassNameTypesByOrchestrations(postprocessedArtifacts), // all the flow class names
    ...map(values(modelClassificationByModelId), 'readAs'), // all the classification models (from the classification_models table- intensity, etc.)
    ...map(
      filter(modelTypes, (modelType) => modelType.trainingType === 'segmentation'),
      'type'
    ), // all the segmentation model types (for the relevant models for annotation assignments)
  ]);

  const isInferenceResultsStepValid = () => {
    // // Check if selected the same model type for each stain
    const slidesModels = getSlidesModelsData(
      keyBy(slides, 'slideId'),
      selectedOrchestrations,
      selectedPostprocessedOrchestrations,
      selectedAssignments,
      relevantModelTypes,
      selectedSlideRegistrations
    );
    const slidesWithoutSomeModels = getSidesWithoutSomeModels(relevantModelTypes, slidesModels);

    // Check if selected classification model for classifications orchestrations
    const modelClassificationsFromClassificationOptions = every(selectedOrchestrations, (selectedOrchestration) =>
      every(
        selectedOrchestration,
        (orchestrationData, modelType) => modelType !== modelTypeClassification.apiModelValue
      )
    );

    // Check if selected annotations assignments have duplicate model types per stain
    const isDuplicateModelTypesPerStain = some(selectedAssignments, (selectedAssignmentsByStain) =>
      some(groupBy(selectedAssignmentsByStain, 'modelType'), (assignments) => size(assignments) > 1)
    );

    const isSelectedOrchestrations = some(
      values(selectedOrchestrations),
      (selectedOrchestration) => !isEmpty(selectedOrchestration)
    );

    const isSelectedPostprocessedOrchestrations = some(
      values(selectedPostprocessedOrchestrations),
      (selectedPostprocessedOrchestration) => !isEmpty(selectedPostprocessedOrchestration)
    );

    const isSelectedAssignments = some(
      flatMap(values(selectedAssignments)),
      (selectedAssignment) => !isEmpty(selectedAssignment)
    );

    const isSelectedAssignmentsValid = every(
      flatMap(values(selectedAssignments)),
      (selectedAssignment) => !isEmpty(selectedAssignment.assignment) && !isEmpty(selectedAssignment.modelType)
    );

    return (
      isEmpty(slidesWithoutSomeModels) &&
      modelClassificationsFromClassificationOptions &&
      !isDuplicateModelTypesPerStain &&
      (isSelectedAssignments ? isSelectedAssignmentsValid : true) &&
      (isSelectedOrchestrations || isSelectedAssignments || isSelectedPostprocessedOrchestrations)
    );
  };

  const isActionsValid = (
    actions: PostProcessingActionCreated[],
    indexForOptions?: number,
    actionsForOptions: PostProcessingActionCreated[] = actions
  ) => {
    let isValid = true;

    forEach(actions, (action, index) => {
      const optionsIndex = indexForOptions ?? index;
      forEach(postProcessingActionsById[action.actionId].inputs, (input) => {
        const selectedStains = getRelevantSelectedStainsForInput(input, action);
        const options =
          input.inputType === InputType.LOGICAL_QUERY
            ? getMappingFiltersMetadataForLogicalQuery(
                action?.stain,
                index,
                actionsForOptions,
                action?.actionInput,
                postProcessingActionsById
              )
            : getOptions(
                input.inputSource,
                selectedStains,
                optionsIndex,
                actionsForOptions,
                action.actionInput,
                get(action, input.inputSourceDependentOn)
              );
        if (!validateInput(get(action, input.inputKey), input, action, options)) {
          isValid = false;
          return false;
        }
      });
    });

    return isValid;
  };

  const isVisualizationStepValid = () => {
    const visualizationUniqIds = uniq(map(visualizations, (visualization) => pick(visualization, ['id', 'stain'])));

    if (visualizationUniqIds.length !== visualizations.length) {
      return false;
    }
    let isValid = true;
    forEach(visualizations, (visualization) => {
      const isClassification = visualization.id === 'classification';
      forEach(visualization?.layers, (layer) => {
        if (
          isEmpty(layer?.classKey) ||
          (!includes(
            classConfigWithSpecialClasses[visualization.stain]?.[visualization.input] as string[],
            layer.classKey
          ) &&
            !includes(
              (postProcessingClasses[visualization.stain]?.[visualization.input] as string[]) || [],
              layer.classKey
            )) ||
          (isClassification &&
            !isNumber(layer.classificationClassKey) &&
            isEmpty(layer.classificationClassKey) &&
            !includes(
              classConfigWithSpecialClasses[visualization.stain]?.[visualization.readAs] || [],
              layer.classificationClassKey as string | number
            ))
        ) {
          isValid = false;
          return false;
        }
      });
    });

    return isValid;
  };

  const isGridParamsStepValid = () => {
    return (
      gridBasedFeaturesStains.length === keys(gridBasedCalculationParams).length &&
      every(gridBasedCalculationParams, (params) => isGridBasedCalculationParamsValid(params))
    );
  };

  const deleteStains = (presetData: JobPreset, stainsToDelete: string[]) => {
    const presetDataWithoutStainsToDelete = cloneDeep(presetData);

    const actionKeys = keys(presetData.presetJson);

    forEach(actionKeys, (key) => {
      const actionIndexToDelete: number[] = [];
      forEach(presetData.presetJson[key], (action, index: number) => {
        if (includes(stainsToDelete, action?.stain)) {
          actionIndexToDelete.push(index);
        } else if (action?.stains && isEmpty(difference(action?.stains, stainsToDelete))) {
          actionIndexToDelete.push(index);
        } else if (action?.stains && some(action?.stains, (stain) => includes(stainsToDelete, stain))) {
          pull(presetDataWithoutStainsToDelete.presetJson[key][index].stains, ...stainsToDelete);
        }
      });

      pullAt(presetDataWithoutStainsToDelete.presetJson[key], actionIndexToDelete);
    });

    return presetDataWithoutStainsToDelete;
  };

  const replaceStains = (presetData: JobPreset, oldStainToNewStain: StainsActions) => {
    const presetDataWithNewStains = cloneDeep(presetData);

    const actionKeys = keys(presetData.presetJson);
    const stainsToReplace = keys(oldStainToNewStain);

    forEach(actionKeys, (key) => {
      forEach(presetData.presetJson[key], (action, index: number) => {
        if (includes(stainsToReplace, action?.stain)) {
          presetDataWithNewStains.presetJson[key][index].stain = oldStainToNewStain[action.stain].reassignTo;
        } else if (some(action?.stains, (stain) => includes(stainsToReplace, stain))) {
          presetDataWithNewStains.presetJson[key][index].stains = uniq(
            map(action.stains, (stain) => {
              if (oldStainToNewStain[stain]) {
                return oldStainToNewStain[stain].reassignTo;
              }
              return stain;
            })
          );
        }
      });
    });

    return presetDataWithNewStains;
  };

  const setPresetOn = (stainsActions: StainsActions, preset?: JobPreset) => {
    const presetData = preset ?? currentPreset;
    let newPreset = cloneDeep(presetData);

    const stainsToDelete = keys(pickBy(stainsActions, (stainAction) => stainAction.action === Action.DELETE));
    if (!isEmpty(stainsToDelete)) {
      newPreset = deleteStains(presetData, stainsToDelete);
    }

    const stainsToReplace = pickBy(stainsActions, (stainAction) => stainAction.action === Action.REASSIGN);
    if (!isEmpty(stainsToReplace)) {
      newPreset = replaceStains(newPreset, stainsToReplace);
    }

    setCalculateFeatureParams(newPreset.presetJson);

    setPresetStainModalOpen(false);
  };

  const selectPreset = (preset: JobPreset) => {
    setCurrentPreset(preset);
    const stainsInPresetNotInCurrent = filter(preset.stains, (stain) => !includes(stains, stain));

    if (!isEmpty(stainsInPresetNotInCurrent)) {
      setPresetStainModalOpen(true);
    } else {
      setPresetOn({}, preset);
    }
  };

  const savePreset = (name: string) => {
    createJobPresetMutation.mutate({
      name,
      stains: stains,
      steps: ['calculate_features'],
      sourceStudyId: studyId,
      presetJson: {
        postprocessing: convertPostProcessingActionsToBackend(postProcessingActionsById, postProcessingActions),
        features: convertFeatureActionsToBackend(postProcessingActionsById, featureActions, newCellsCreated),
        visualizations: visualizations,
        gridBasedCalculationParams: gridBasedCalculationParams,
      },
    });
    setIsAddingPreset(false);
    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Saving preset...</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_SAVE_PRESET,
      autoHideDuration: null,
    });
  };

  const updatePreset = () => {
    updateJobPresetMutation.mutate({
      id: currentPreset.id,
      stains: stains,
      steps: ['calculate_features'],
      sourceStudyId: studyId,
      presetJson: {
        postprocessing: convertPostProcessingActionsToBackend(postProcessingActionsById, postProcessingActions),
        features: convertFeatureActionsToBackend(postProcessingActionsById, featureActions, newCellsCreated),
        visualizations: visualizations,
      },
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Updating preset...</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_UPDATE_PRESET,
      autoHideDuration: null,
    });
  };

  const steps = [
    {
      label: 'Pipeline Stage Name',
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {isLoadingPipsRunNames ? (
              <Skeleton variant="text" height={40} width={200} />
            ) : isErrorPipRunNames ? (
              <Typography variant="body1" color="error">
                Error loading pipeline stage names
              </Typography>
            ) : (
              <Select
                value={selectedPipsRunName}
                onChange={(event) => setSelectedPipsRunName(event.target.value as string)}
                variant="outlined"
                fullWidth
                disabled={isLoadingPipsRunNames}
              >
                {map(pipRunNames, (pipRunName: PipRunName) => (
                  <MenuItem key={pipRunName.id} value={pipRunName.name}>
                    {humanize(pipRunName.name)}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Inference Model And Assignments For Slides',
      content: (
        <InferenceModelsForSlides
          studyId={studyId}
          encodedQueryForRegistrations={encodedQuery}
          slides={slides}
          slideStainTypes={slideStainTypes}
          inferenceModels={inferenceArtifacts}
          postprocessedArtifacts={postprocessedArtifacts}
          modelsType={relevantModelTypes}
          isLoading={
            isInferenceArtifactsLoading ||
            isInferenceArtifactsFetching ||
            isPostprocessedArtifactsLoading ||
            isPostprocessedArtifactsFetching ||
            !finishLoadingAnnotationAssignments
          }
          selectedOrchestrations={selectedOrchestrations}
          setSelectedOrchestrations={setSelectedOrchestrations}
          selectedPostprocessedOrchestrations={selectedPostprocessedOrchestrations}
          setSelectedPostprocessedOrchestrations={setSelectedPostprocessedOrchestrations}
          displayAssignmentAnnotations
          displayPostProcessing
          assignmentAnnotationsByStainType={annotationAssignmentsByStain}
          selectedAssignments={selectedAssignments}
          setSelectedAssignments={setSelectedAssignments}
          modelClassificationByModelId={modelClassificationByModelId}
          setModelClassificationByModelId={(modelId: string, classification: string) => {
            if (isEmpty(classification)) {
              setModelClassificationByModelId((prev) => {
                const newModelClassificationByModelId = cloneDeep(prev);
                delete newModelClassificationByModelId[modelId];
                return newModelClassificationByModelId;
              });
            } else {
              setModelClassificationByModelId((prev) => ({
                ...prev,
                [modelId]: {
                  readAs: classification,
                },
              }));
            }
          }}
          setIntensityClassificationBinning={(modelId: string, binning: ClassificationBinningParams) => {
            setModelClassificationByModelId((prev) => ({
              ...prev,
              [modelId]: {
                ...prev[modelId],
                numBins: binning?.numBins,
                mapValuesToBins: binning?.mapValuesToBins,
              },
            }));
          }}
          selectedSlideRegistrations={selectedSlideRegistrations}
          setSelectedSlideRegistration={setSelectedSlideRegistration}
          {...(includes(slideStainTypes, MULTIPLEX_STAIN_ID)
            ? {
                multiplexGeneralInputsProps: {
                  selectedThresholdJobId,
                  setSelectedThresholdJobId,
                  onThresholdJobSelect,
                  onCellRulesChange,
                  selectedCellTypingJobId,
                  setSelectedCellTypingJobId,
                  onCellTypingJobSelect,
                },
              }
            : {})}
        />
      ),
      onNextOrBackClick: () => {
        setClassConfigFromSelectedResults(
          slidesById,
          selectedOrchestrations,
          selectedPostprocessedOrchestrations,
          modelClassificationByModelId,
          selectedAssignments
        );
        setIsStepFailed((prev) => ({
          ...prev,
          [CalculateFeaturesStep.SelectModels]: !isInferenceResultsStepValid(),
        }));
      },
    },
    {
      label: 'Presets',
      content: (
        <Grid>
          <PresetSection
            selectedPreset={currentPreset}
            presets={map(
              filter(presets, (preset) => !preset.deletedBy),
              (preset) => ({
                ...preset,
                displayName: (
                  <Grid container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="body1">{preset.name}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{moment(preset.createdAt).format('YYYY-MM-DD HH:mm')}</Typography>
                    </Grid>
                    {preset.updatedAt && (
                      <Grid item>
                        <Typography variant="body2">
                          (updated at: {moment(preset.updatedAt).format('YYYY-MM-DD HH:mm')})
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      {isLoadingStainTypeOptions ? (
                        <CircularProgress />
                      ) : (
                        <Typography variant="caption">
                          (
                          {join(
                            map(preset.stains, (stain) => stainTypeIdToDisplayName(stain)),
                            ', '
                          )}
                          )
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ),
              })
            )}
            onSavePreset={undefined}
            onSelectPreset={selectPreset}
            label={
              <>
                calculate features presets{' '}
                {isLoadingPresets && <CircularProgress size={18.5} title="Loading calculate features presets..." />}
              </>
            }
            isLoading={isLoadingPresets}
          />
        </Grid>
      ),
      onNextOrBackClick: () => {
        setIsStepFailed((prev) => ({
          ...prev,
          [CalculateFeaturesStep.Postprocessing]: !isActionsValid(postProcessingActions),
          [CalculateFeaturesStep.Features]: !isActionsValid(
            featureActions,
            postProcessingActions.length,
            postProcessingActions
          ),
          [CalculateFeaturesStep.Visualizations]: !isVisualizationStepValid(),
        }));
      },
    },
    {
      label: 'Post Processing Actions',
      content: (
        <PostProcessingActions
          actions={postProcessingActions}
          setActions={setPostProcessingActions}
          getOptions={(inputSource, selectedStains, index, actionInput, inputSourceDependentOn) =>
            getOptions(inputSource, selectedStains, index, postProcessingActions, actionInput, inputSourceDependentOn)
          }
          getOptionsByValue={(inputSource, selectedStains, index, actionInput, inputSourceDependentOn) =>
            getOptionsByValue(
              inputSource,
              selectedStains,
              index,
              postProcessingActions,
              actionInput,
              inputSourceDependentOn
            )
          }
          getMappingFiltersMetadataForLogicalQuery={(stain, actionInput, index) =>
            getMappingFiltersMetadataForLogicalQuery(
              stain,
              index,
              postProcessingActions,
              actionInput,
              postProcessingActionsById
            )
          }
        />
      ),
      onNextOrBackClick: () => {
        setPostProcessingClasses(getOutputNamesForAllStains(postProcessingActions.length, postProcessingActions));
        setIsStepFailed((prev) => ({
          ...prev,
          [CalculateFeaturesStep.Postprocessing]: !isActionsValid(postProcessingActions),
          [CalculateFeaturesStep.Visualizations]: !isVisualizationStepValid(),
        }));
      },
    },
    {
      label: 'Features',
      content: (
        <FeatureActions
          stains={stains}
          actions={featureActions}
          setActions={setFeatureActions}
          newCells={newCellsCreated}
          setNewCells={setNewCellsCreated}
          getOptions={(inputSource, selectedStains, index, actionInput, inputSourceDependentOn) =>
            getOptions(
              inputSource,
              selectedStains,
              postProcessingActions.length,
              postProcessingActions,
              actionInput,
              inputSourceDependentOn
            )
          }
          getOptionsByValue={(inputSource, selectedStains, index, actionInput, inputSourceDependentOn) =>
            getOptionsByValue(
              inputSource,
              selectedStains,
              postProcessingActions.length,
              postProcessingActions,
              actionInput,
              inputSourceDependentOn
            )
          }
          getMappingFiltersMetadataForLogicalQuery={(stain, actionInput) =>
            getMappingFiltersMetadataForLogicalQuery(
              stain,
              postProcessingActions.length,
              postProcessingActions,
              actionInput,
              postProcessingActionsById
            )
          }
          getAdditionalInputError={getAdditionalInputErrorMessage}
        />
      ),
      onNextOrBackClick: () => {
        setIsStepFailed((prev) => ({
          ...prev,
          [CalculateFeaturesStep.Features]: !isActionsValid(
            featureActions,
            postProcessingActions.length,
            postProcessingActions
          ),
        }));
      },
    },
    {
      label: 'Grid based params',
      content: (
        <>
          {isEmpty(gridBasedFeaturesStains) ? (
            <Typography>No grid based features selected, you can skip this step</Typography>
          ) : (
            map(gridBasedFeaturesStains, (stain) => (
              <GridBasedCalculationParamsStep
                key={stain}
                stain={stain}
                gridBasedCalculationParams={
                  gridBasedCalculationParams[stain] || {
                    gridSizeUm: [],
                    minCellsThreshold: {},
                    minAreaFractionThreshold: {},
                  }
                }
                setGridBasedCalculationParams={(params: GridBasedCalculationParams) =>
                  setGridBasedCalculationParams((prev) => ({ ...prev, [stain]: params }))
                }
              />
            ))
          )}
        </>
      ),
      onNextOrBackClick: () => {
        setIsStepFailed((prev) => {
          return {
            ...prev,
            [CalculateFeaturesStep.GridBasedCalculation]: !isGridParamsStepValid(),
          };
        });
      },
    },
    {
      label: 'Visualization',
      content: (
        <VisualizationStep
          classConfig={classConfigWithSpecialClasses}
          postProcessingClasses={postProcessingClasses}
          visualizations={visualizations}
          setVisualizations={setVisualizations}
          stains={stains}
          classificationModelOptions={selectedClassificationModelsOptions}
        />
      ),
      onNextOrBackClick: () => {
        setIsStepFailed((prev) => ({ ...prev, [CalculateFeaturesStep.Visualizations]: !isVisualizationStepValid() }));
      },
    },
    {
      label: 'Summary and Save',
      content: (
        <Grid alignContent="flex-start">
          <Collapse in={!isAddingPreset}>
            <PresetActionButtons
              setIsAddingPreset={setIsAddingPreset}
              selectedPreset={currentPreset}
              onUpdatePreset={() => {
                updatePreset();
              }}
              updateLabel={`Update preset- `}
              createLabel="Save as preset..."
            />
          </Collapse>
          <SavePreset
            isAddingPreset={isAddingPreset}
            setIsAddingPreset={setIsAddingPreset}
            isLoading={false}
            savePreset={savePreset}
          />
          <Grid container direction="column" spacing={2} mt={1}>
            <Grid item>
              <TextField
                label="Job Name"
                value={jobSummaryData.name}
                onChange={(e) => setJobSummaryData((prev) => ({ ...prev, name: e.target.value }))}
                placeholder="Type Here"
              />
            </Grid>
            <Grid item>
              <TextField
                label="Job Description"
                value={jobSummaryData.description}
                onChange={(e) => setJobSummaryData((prev) => ({ ...prev, description: e.target.value }))}
                placeholder="Type Here"
                multiline
                minRows={4}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
    },
  ];

  const message =
    !isEmpty(location.state?.jobId) && isEmpty(job?.studyId)
      ? isJobError
        ? "Error occurred, Can't get job data."
        : 'Load job data'
      : isEmpty(studyId)
      ? 'No study selected'
      : modelTypesError || isInferenceArtifactsError || isPostprocessedArtifactsError || isSlidesError
      ? "Error occurred, Can't get data from the server."
      : null;

  return (
    <>
      <Grid container direction="column" spacing={2} mt={1}>
        <PageHeader pageTitle="Calculate Features" onBackClick={() => navigate(-1)} />
        {!isEmpty(message) ? (
          <Grid item>
            <Typography>{message}</Typography>
          </Grid>
        ) : (
          <Grid item>
            <PlatformStepper
              validateStep={async () => {
                return true;
              }}
              handleSubmit={() => {
                onSubmit();
              }}
              steps={steps}
              setActiveStepForValidation={setActiveStep}
              isStepFailed={isStepFailed}
            />
          </Grid>
        )}
      </Grid>
      {presetStainModalOpen && (
        <PresetStainModal
          currentStains={stains}
          presetStains={currentPreset?.stains}
          onFinish={(stainsActions: StainsActions) => setPresetOn(stainsActions)}
        />
      )}
    </>
  );
};

export default wrapPage(CalculateFeatures, {}, false);
