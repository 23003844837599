import { useSignals } from '@preact/signals-react/runtime';
import { forEach, isEmpty } from 'lodash';
import { useEffect, useState, useTransition } from 'react';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import usePrevious from 'utils/usePrevious';
import {
  LayerVisualizationChange,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from '../../../slidesVisualizationAndConfiguration';
import { computeDefaultPmtLayerSettings, getPmtLayerId } from './ProtomapTileControl';

export const useUpdatePmtHeatmapsSettingsOnChange = ({
  slideId,
  viewerIndex,
  stainTypeId,
  layerIdsToUrlKeys,
  pmtHeatmaps,
}: {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  layerIdsToUrlKeys?: Record<string, string>;
  pmtHeatmaps: FeatureMetadata[];
}) => {
  useSignals();
  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const previousSlideParams = usePrevious({ slideId, viewerIndex });
  const [didApplyInitialSettings, setDidApplyInitialSettings] = useState(false);
  const [, startTransition] = useTransition();
  useEffect(() => {
    if (
      !slideId ||
      (didApplyInitialSettings &&
        previousSlideParams?.slideId === slideId &&
        previousSlideParams?.viewerIndex === viewerIndex)
    ) {
      return;
    }
    setDidApplyInitialSettings(false);
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    const changes: LayerVisualizationChange[] = [];
    forEach(pmtHeatmaps, (pmtHeatmap) => {
      const heatmapOptions = pmtHeatmap?.nestedItems || [];
      if (!isEmpty(heatmapOptions)) {
        forEach(heatmapOptions, (heatmapOption, layerIndex) => {
          const layerName = heatmapOption?.key;
          const computedLayerSettings = computeDefaultPmtLayerSettings(pmtHeatmap, layerIndex, layerName);
          const layerSettingsKey = getPmtLayerId(pmtHeatmap, layerName);
          const previousSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey]?.value;
          const layerUrlKey = layerIdsToUrlKeys?.[layerSettingsKey] || layerSettingsKey;
          const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
          changes.push({
            layerId: layerSettingsKey,
            newSettings: {
              ...computedLayerSettings,
              ...(Boolean(previousSettings) && previousSettings),
              ...urlSettings,
            },
          });
        });
      }
    });
    if (isEmpty(changes)) {
      return;
    }
    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      skipUrlUpdate: true,
      changeFlow: `PmtHeatmaps-initial`,
    });
    startTransition(() => {
      setDidApplyInitialSettings(true);
    });
  }, [viewerIndex, slideId, stainTypeId, pmtHeatmaps, layerIdsToUrlKeys, getLayerSettingsFromUrl]);

  return { didApplyInitialSettings };
};
