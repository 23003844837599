import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Link,
  Skeleton,
  Typography
} from '@mui/material';
import { FlowClassName } from 'interfaces/experimentResults';
import { CalculateFeaturesManifest, InferenceManifest, Job, JobType, SlideRegistrationsManifest } from 'interfaces/job';
import { filter, flatMap, fromPairs, groupBy, includes, isEmpty, keyBy, map, toPairs, values } from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';

const SKIP = 'skip';

interface SubtaskDetailsProps {
  job: Job;
  isLoading?: boolean;
}

const getSlideRegistrationKey = (registrationSlides: { slide_id1: string, slide_id2: string }) => `${registrationSlides.slide_id1}:${registrationSlides.slide_id2}`;

export const SubtaskDetails: React.FC<SubtaskDetailsProps> = ({ job, isLoading = false }) => {
  const { labId } = useCurrentLabId();

  const { uiSettings } = useUiSettings();
  const jobManifest = job?.manifest;
  const slideIds =
    job?.type === JobType.PrepareCustomerResults
      ? []
      : job?.type === JobType.CalculateFeatures
        ? flatMap(jobManifest)
        : jobManifest;

  const subTasks = filter(
    job?.children,
    (childrenJob) => childrenJob?.flowClassName &&
      includes([FlowClassName.ConcurrentInferenceFlow, FlowClassName.CalculateFeatures, FlowClassName.PerformRegistrationFlow], childrenJob.flowClassName)
  );

  const subTasksStatusBySlide = keyBy(
    flatMap(subTasks, (inferenceFlow: Job) => {
      if (inferenceFlow?.flowClassName === FlowClassName.ConcurrentInferenceFlow) {
        return map(inferenceFlow?.manifest as InferenceManifest, (slide: { slide_id: string }) => {
          return {
            slideId: slide.slide_id,
            status: inferenceFlow?.status,
          };
        });
      } else if (inferenceFlow?.flowClassName === FlowClassName.CalculateFeatures) {
        const manifest = inferenceFlow?.manifest as CalculateFeaturesManifest;
        const manifestSlides = flatMap(manifest, (entry) => values(entry.slides));
        return map(manifestSlides, (slideId: string) => ({
          slideId,
          status: inferenceFlow?.status,
        }));
      } else if (inferenceFlow?.flowClassName === FlowClassName.PerformRegistrationFlow) {
        return map(inferenceFlow?.manifest as SlideRegistrationsManifest, (registrationSlides: { slide_id1: string, slide_id2: string }) => {
          return {
            slideId: getSlideRegistrationKey(registrationSlides),
            status: inferenceFlow?.status,
          };
        })
      }
    }),
    'slideId'
  );

  const lengthSubTasksByStatus = fromPairs(
    map(toPairs(groupBy(subTasks, 'status')), ([key, value]) => [key, value.length])
  );

  // No row will be created in the database if inference was previously run on one of the slides with the same parameters.
  // In this case, the number of inference sub tasks will be smaller than the number of slides in the manifest
  // When the job is completed, we know this is the case, otherwise, we sum the number of sub tasks by the job status.
  const defaultSubTaskStatusByJobStatus =
    job?.status === 'completed' ? SKIP : job?.status === 'running' ? 'pending' : job?.status;

  if (subTasks.length < jobManifest?.length) {
    const difference = jobManifest.length - subTasks.length;

    lengthSubTasksByStatus[defaultSubTaskStatusByJobStatus] =
      (lengthSubTasksByStatus[defaultSubTaskStatusByJobStatus] ?? 0) + difference;
  }

  const jobStatusToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['jobStatus'], ({ value, label }) => [value, label]) || []
  );
  jobStatusToDisplayText[SKIP] = 'Skipped';

  const { getUrlToSlidePage } = useNavigationToViewerPage();

  return (
    <Grid item>
      {isLoading ? (
        <Skeleton variant="rectangular" height={150} />
      ) : (
        !isEmpty(job?.manifest) && (
          <Accordion defaultExpanded slotProps={{ transition: { unmountOnExit: true } }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h4">Sub Tasks</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item container direction="column" spacing={1}>
                <Grid item container direction="column">
                  {map(toPairs(lengthSubTasksByStatus), ([key, value]) => {
                    return (
                      <Grid item container justifyContent="space-between">
                        <Grid item>
                          <Typography variant="h5">{value}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">{jobStatusToDisplayText[key]}</Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4">Slide Id</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">Status</Typography>
                  </Grid>
                </Grid>
                {job?.type === JobType.SlideRegistrations ? map(slideIds, (registrationSlides: { slide_id1: string, slide_id2: string }) => {
                  return (
                    <Grid item container alignItems="center" justifyContent="space-between">
                      <Grid item xs={9}>
                        <Link
                          to={getUrlToSlidePage({
                            slideId: registrationSlides.slide_id1,
                            labId,
                            caseStudyId: job?.studyId,
                          })}
                          target="_blank"
                          rel="noopener noreferrer"
                          component={RouterLink}
                        >
                          <Typography variant="h5">{`${registrationSlides.slide_id1}`}</Typography>
                        </Link>
                        <Link
                          to={getUrlToSlidePage({
                            slideId: registrationSlides.slide_id2,
                            labId,
                            caseStudyId: job?.studyId,
                          })}
                          target="_blank"
                          rel="noopener noreferrer"
                          component={RouterLink}
                        >
                          <Typography variant="h5">{`${registrationSlides.slide_id2}`}</Typography>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Typography variant="h5">
                          {
                            jobStatusToDisplayText[
                            subTasksStatusBySlide[getSlideRegistrationKey(registrationSlides)]?.status || defaultSubTaskStatusByJobStatus
                            ]
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                }) : map(slideIds, (slideId: string) => {
                  return (
                    <Grid item container alignItems="center" justifyContent="space-between">
                      <Grid item xs={9}>
                        <Link
                          to={getUrlToSlidePage({
                            slideId,
                            labId,
                            caseStudyId: job?.studyId,
                          })}
                          target="_blank"
                          rel="noopener noreferrer"
                          component={RouterLink}
                        >
                          <Typography variant="h5">{slideId}</Typography>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Typography variant="h5">
                          {
                            jobStatusToDisplayText[
                            subTasksStatusBySlide[slideId]?.status || defaultSubTaskStatusByJobStatus
                            ]
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )
      )}
    </Grid>
  );
};
