import { Viewport } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import { List, ListItemButton, Paper, Popover } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

import HtmlDeckGLOverlay from 'components/HtmlDeckGLOverlay';
import HtmlDeckGLOverlayItem from 'components/HtmlDeckGLOverlay/HtmlDeckGLOverlayItem';
import {
  editTypeToShapeSubTypeMap,
  SlideAnnotationEditType,
} from 'components/Procedure/Header/SlideInteractionMenu/slideAnnotations/options';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Permission } from 'interfaces/permissionOption';
import { cloneDeep, concat, get, map } from 'lodash';
import { usePermissions } from 'utils/usePermissions';
import { viewerClickData } from '../../viewerDataSignals';
import { useActiveAnnotationDraft, viewerAnnotationData } from './useActiveAnnotationDraft';
import useActiveClassToAnnotate from './useActiveClassToAnnotate';
import useAnnotationsForViewer, { UNKNOWN_DIAGNOSIS } from './useAnnotations';

const DEFAULT_COORDINATES = [0, 0] as [number, number];
const FIXED_ROI_SIZES: [number, number][] = [
  [150, 150],
  [300, 300],
];

export const RoiFixedSizesMenu: React.FC<{
  slide: SlideWithChannelAndResults;
  viewport: Viewport;
  clickInfo: Parameters<DeckGLProps['onClick']>[0];
}> = ({ slide, viewport, clickInfo }) => {
  useSignals();

  const coordinate = clickInfo.coordinate as [number, number];

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement>(null);

  // allow escape functionality
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Escape') {
      if (viewerClickData[slide?.viewerIndex]) {
        viewerClickData[slide?.viewerIndex].value = null;
      }
    }
  };

  const activeAnnotationClass = useActiveClassToAnnotate({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });
  const { savedAnnotationData } = useAnnotationsForViewer({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });
  const { activeAnnotationData } = useActiveAnnotationDraft({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });

  const handleAddFixedRoi = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, roiSize: [number, number]) => {
    event.stopPropagation();
    const currentFeatureCollection = cloneDeep(activeAnnotationData);
    const newRoiProperties: {
      [key: string]: any;
    } = {};
    newRoiProperties.shapeSubType = get(editTypeToShapeSubTypeMap, SlideAnnotationEditType.Roi, '');
    newRoiProperties.annotationId = savedAnnotationData?.annotationId;
    newRoiProperties.markerType = 'tagger_annotation';
    if (activeAnnotationClass?.name) {
      if (activeAnnotationClass.isMarkerAnnotation) {
        console.warn('Roi should not be marker annotation');
        newRoiProperties.diagnosis = UNKNOWN_DIAGNOSIS;
        newRoiProperties.markerPositivity = {
          [activeAnnotationClass.name]: activeAnnotationClass.positive,
        };
      } else {
        newRoiProperties.diagnosis = activeAnnotationClass.name;
        newRoiProperties.markerPositivity = activeAnnotationClass?.markerPositivity;
      }
    }
    const newFeatureCollection = {
      ...currentFeatureCollection,
      features: concat(currentFeatureCollection?.features, {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              coordinate,
              [coordinate[0] + roiSize[0], coordinate[1]],
              [coordinate[0] + roiSize[0], coordinate[1] + roiSize[1]],
              [coordinate[0], coordinate[1] + roiSize[1]],
              coordinate,
            ],
          ],
        },
        properties: newRoiProperties,
      }),
    };
    viewerAnnotationData[slide?.viewerIndex].value = newFeatureCollection;
    if (viewerClickData[slide?.viewerIndex]) {
      viewerClickData[slide?.viewerIndex].value = null;
    }
  };

  return (
    canAnnotateSlides && (
      <HtmlDeckGLOverlay viewport={viewport}>
        <HtmlDeckGLOverlayItem key="contextMenu" draggable={false} coordinates={coordinate || DEFAULT_COORDINATES}>
          <div ref={(el) => setAnchorEl(el)} />

          <Popover
            id="roiFixedSizesMenu"
            open={Boolean(coordinate) && Boolean(anchorEl)}
            anchorEl={anchorEl}
            disableEscapeKeyDown={false}
            onKeyDown={handleKeyDown}
          >
            <Paper>
              <List>
                {map(FIXED_ROI_SIZES, (roiSize) => (
                  <ListItemButton onClick={(event) => handleAddFixedRoi(event, roiSize)} tabIndex={0}>
                    {roiSize[0]} x {roiSize[1]}
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          </Popover>
        </HtmlDeckGLOverlayItem>
      </HtmlDeckGLOverlay>
    )
  );
};
