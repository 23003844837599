import { useSignals } from '@preact/signals-react/runtime';
import { Feature } from '@turf/helpers';
import { getSlideAnnotationsQueryKey, publishAnnotationVersion, saveAnnotationDraft } from 'api/annotations';
import { ShapeSubTypes } from 'components/Procedure/Header/SlideInteractionMenu/slideAnnotations/options';
import {
  getAnnotationSettingsKey,
  getAnnotationTodoNameAndDefinition,
} from 'components/Procedure/Infobar/SlideInfobar/SlideAnnotation/helpers';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { Annotation, TodoOption } from 'interfaces/annotation';
import { Permission } from 'interfaces/permissionOption';
import { cloneDeep, compact, find, first, fromPairs, includes, isEqual, isNil, keys, map, pick, values } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import markerAnnotationService from 'services/annotations/markerAnnotationService';
import { useActiveAnnotationAssignmentForViewer } from 'services/annotations/useAnnotationQueryParams';
import { getColorHex, hexToRgb } from 'utils/helpers';
import queryClient from 'utils/queryClient';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { usePermissions } from 'utils/usePermissions';
import { FeatureCollection, GeoJsonFeature } from '../NebulaGLExtensions/geojson-types';
import { RGBAColor } from '../NebulaGLExtensions/helpers';
import { useActiveAnnotationDraft, viewerAnnotationData } from './useActiveAnnotationDraft';
import { AnnotationState, viewerAnnotationSettings } from './useActiveClassToAnnotate';

export interface AnnotationItem extends TodoOption {
  selected?: boolean;
  icon?: React.ReactNode;
}

export const UNKNOWN_DIAGNOSIS = 'unknown';

const useAnnotationsForViewer = ({ slideId, viewerIndex }: { slideId: string; viewerIndex: number }) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const { activeAnnotationData, slideAnnotations, isLoading } = useActiveAnnotationDraft({
    slideId,
    viewerIndex,
  });

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];

  const [activeAnnotationAssignmentId] = useActiveAnnotationAssignmentForViewer(viewerIndex);

  const savedAnnotationData = canAnnotateSlides
    ? find(slideAnnotations, { annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId } })
    : undefined;
  const { todo } = getAnnotationTodoNameAndDefinition(savedAnnotationData);

  const visualSettingsForAnnotation = fromPairs(
    map(todo?.options, (option) => {
      const annotationSettingsKey = getAnnotationSettingsKey(savedAnnotationData, option?.name);
      return [annotationSettingsKey, slideLayerVisualizationSettings?.[annotationSettingsKey]?.value];
    })
  );

  const getSettingsForFeature = useCallback(
    (feature: Feature) => {
      const annotationId = feature?.properties?.annotationId;
      const diagnosis = feature?.properties?.diagnosis;
      const isMarkerAnnotation = diagnosis === UNKNOWN_DIAGNOSIS;
      // If we don't know the diagnosis, take the first marker positivity
      const marker = isMarkerAnnotation ? first(keys(feature?.properties?.markerPositivity)) : null;
      // The marker annotation is saved in the todo as the option name
      // And in the annotation itself it is saved inside the marker positivity and not the diagnosis
      const diagnosisForSettings = isMarkerAnnotation ? marker : diagnosis;
      const annotation = find(slideAnnotations, { annotationId });

      const annotationSettingsKey = annotation ? getAnnotationSettingsKey(annotation, diagnosisForSettings) : undefined;
      const featureSettings = annotationSettingsKey ? visualSettingsForAnnotation?.[annotationSettingsKey] : undefined;
      // If there is no diagnosis there is no settings (meaning opacity 0) and we still want to show the feature
      const opacity = !diagnosis
        ? 255
        : featureSettings?.selected && featureSettings?.show
        ? ((featureSettings?.opacity ?? 100) / 100) * 255
        : 0;

      let color = featureSettings?.color;

      if (!color) {
        const annotationTodo = getAnnotationTodoNameAndDefinition(annotation);

        // Use the color from the todo option if it exists, otherwise look in all todos
        const todoOptions = annotationTodo?.todo?.options;
        const matchingOption = find(todoOptions, { name: diagnosisForSettings });
        color = matchingOption?.color;
      }
      return { ...featureSettings, opacity, color };
    },
    [slideAnnotations, JSON.stringify(visualSettingsForAnnotation)]
  );

  const getColorForFeature = useCallback(
    (feature: Feature, isSelected: boolean, baseOpacity: number = 100): RGBAColor => {
      const matchingVisualizationSetting = getSettingsForFeature(feature);
      const opacity = (matchingVisualizationSetting?.opacity ?? 100) * baseOpacity * (isSelected ? 1 : 0.5);

      return matchingVisualizationSetting.color
        ? [...hexToRgb(getColorHex(matchingVisualizationSetting.color)), opacity]
        : [180, 180, 180, opacity];
    },
    [getSettingsForFeature]
  );

  /**
   * Get fill color for feature
   * If the feature is a negative marker annotation then the fill color should be a bit transparent
   *
   * @param feature - The feature to get the fill color for
   * @returns The fill color for the feature
   */
  const getFillColorForFeature = useCallback(
    (feature: Feature, isSelected: boolean, opacity?: number) => {
      if (opacity) {
        return getColorForFeature(feature, isSelected, opacity);
      }

      let defaultOpacity = 1;

      const diagnosis = feature?.properties?.diagnosis;
      const isMarkerAnnotation = diagnosis === UNKNOWN_DIAGNOSIS;
      // Take the first marker positivity (if we don't know the diagnosis, color the feature by the marker)
      const markerValue = isMarkerAnnotation ? first(values(feature?.properties?.markerPositivity)) : null;
      if (
        (isMarkerAnnotation && !isNil(markerValue) && !markerValue) ||
        feature?.properties?.shapeSubType !== ShapeSubTypes.Point
      ) {
        defaultOpacity = 0.5;
      }

      return getColorForFeature(feature, isSelected, defaultOpacity);
    },
    [getColorForFeature]
  );

  const getTodoOptionColor = useCallback((annotation: Annotation, option: TodoOption) => {
    const annotationSettingsKey = annotation ? getAnnotationSettingsKey(annotation, option.name) : undefined;
    const featureSettings = annotationSettingsKey ? visualSettingsForAnnotation?.[annotationSettingsKey] : undefined;
    return featureSettings?.color;
  }, []);

  const getFilteredAnnotationItemsByFeatureIndex = useCallback(
    (featureIndex: number) => {
      const annotationFeatures = activeAnnotationData?.features;
      const feature = annotationFeatures?.[featureIndex];
      const annotationId = feature?.properties?.annotationId;
      const annotation = find(slideAnnotations, { annotationId });
      const isPoint = feature?.geometry.type === 'Point';
      const isRoi = feature?.properties?.shapeSubType === 'rect';
      const isPolygon = feature?.geometry.type === 'Polygon';

      return feature?.properties?.markerType === 'tagger_annotation'
        ? compact(
            map(todo?.options, (option) => {
              const color = getTodoOptionColor(annotation, option);
              return !option.className ||
                (isPoint && getShapeSubTypeFromTodoOption(option) === ShapeSubTypes.Point) ||
                (isRoi && getShapeSubTypeFromTodoOption(option) === ShapeSubTypes.Rect) ||
                (isPolygon && getShapeSubTypeFromTodoOption(option) === ShapeSubTypes.Polygon)
                ? {
                    ...option,
                    positive: option.positive,
                    color: color ?? option.color,
                    selected: option.name === feature.properties.optionName,
                    isPoint,
                  }
                : null;
            })
          )
        : [];
    },
    [activeAnnotationData, todo, getFillColorForFeature]
  );

  const updateAnnotationSettings = (todoOption: TodoOption) => {
    if (viewerAnnotationSettings[viewerIndex]) {
      const newClassesToAnnotatePerGeometry: { [K in ShapeSubTypes]: AnnotationState } =
        viewerAnnotationSettings[viewerIndex].value?.currentClassToAnnotatePerGeometry ||
        ({} as { [K in ShapeSubTypes]: AnnotationState });
      const optionShapeSubType = getShapeSubTypeFromTodoOption(todoOption);
      newClassesToAnnotatePerGeometry[optionShapeSubType] = {
        name: todoOption.name,
        ...(todoOption.isMarkerAnnotation ? { positive: todoOption.positive ?? true } : {}),
      };
      viewerAnnotationSettings[viewerIndex].value = {
        ...(viewerAnnotationSettings[viewerIndex].value || {}),
        currentClassToAnnotatePerGeometry: newClassesToAnnotatePerGeometry,
      };
    }
  };

  const annotationItems = map(todo?.options, (option) => {
    const color = getTodoOptionColor(savedAnnotationData, option);
    return {
      ...option,
      color: color ?? option.color,
      selected: false,
    };
  });

  const savedAnnotation = useMemo(
    () =>
      find(slideAnnotations, {
        annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId },
      }),
    [slideAnnotations, activeAnnotationAssignmentId]
  );

  const getFeaturesForComparison = useCallback(
    ({ draft = false }: { draft?: boolean } = {}): GeoJsonFeature[] => {
      const featureCollection = markerAnnotationService.convertAnnotationToGeoJson({
        annotation: cloneDeep(savedAnnotation),
        draft,
      });

      return map(featureCollection.features, (feature) => {
        return {
          ...feature,
          properties: getPropertiesForComparison(feature.properties),
        };
      });
    },
    [savedAnnotation]
  );

  const savedAnnotationDraftFeatureCollection = useMemo(
    () => getFeaturesForComparison({ draft: true }),
    [getFeaturesForComparison]
  );
  const publishedAnnotationsFeatureCollection = useMemo(() => getFeaturesForComparison(), [getFeaturesForComparison]);

  const viewerFeatureForComparison: GeoJsonFeature[] = map(
    viewerAnnotationData[viewerIndex]?.value?.features,
    (features) => {
      return {
        ...features,
        properties: getPropertiesForComparison(features.properties),
      };
    }
  );

  const draftDataSaved =
    viewerAnnotationData[viewerIndex]?.value === null ||
    isEqual(viewerFeatureForComparison, savedAnnotationDraftFeatureCollection);

  const publishedAnnotationsSaved = useMemo(
    () => isEqual(savedAnnotationDraftFeatureCollection, publishedAnnotationsFeatureCollection),
    [savedAnnotationDraftFeatureCollection, publishedAnnotationsFeatureCollection]
  );

  const saveDraftMutation = useMutationWithErrorSnackbar({
    mutationFn: saveAnnotationDraft,
    mutationDescription: 'save annotation draft',
    onSuccess: (data, { annotationAssignmentId, slideId: updatedSlideId, annotationsData }) => {
      queryClient.setQueryData(
        getSlideAnnotationsQueryKey({ slideId: updatedSlideId, includeEmpty: true }),
        (oldData: Annotation[]) => {
          if (oldData) {
            return map(oldData, (annotation) => {
              if (annotation.annotationAssignment.annotationAssignmentId === annotationAssignmentId) {
                return {
                  ...annotation,
                  draftAnnotationsData: annotationsData,
                };
              }
              return annotation;
            });
          }
          return oldData;
        }
      );
    },
  });

  const publishAnnotationsMutation = useMutationWithErrorSnackbar({
    mutationFn: publishAnnotationVersion,
    mutationDescription: 'publish annotations',
    onMutate: ({ annotationAssignmentId, slideId: updatedSlideId }) => {
      queryClient.setQueryData(
        getSlideAnnotationsQueryKey({ slideId: updatedSlideId, includeEmpty: true }),
        (oldData: Annotation[]) => {
          if (oldData) {
            return map(oldData, (annotation) => {
              if (annotation.annotationAssignment.annotationAssignmentId === annotationAssignmentId) {
                return {
                  ...annotation,
                  annotationsData: annotation.draftAnnotationsData,
                };
              }
              return annotation;
            });
          }
          return oldData;
        }
      );
    },
    onSuccess: () => {
      enqueueSnackbar('Annotations published', { variant: 'success' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(getSlideAnnotationsQueryKey({ slideId: slideId, includeEmpty: true }));
    },
  });

  const saveDraft = ({
    assignmentId,
    featureCollection,
    showSuccessSnackbar = true,
  }: {
    assignmentId: number;
    featureCollection: FeatureCollection;
    showSuccessSnackbar?: boolean;
  }) => {
    saveDraftMutation.mutate(
      {
        annotationAssignmentId: assignmentId,
        slideId,
        annotationsData: [markerAnnotationService.convertGeoJsonToAnnotationsData(featureCollection)],
      },
      {
        onSuccess: () => {
          if (showSuccessSnackbar) {
            enqueueSnackbar('Annotation saved', { variant: 'success' });
          }
        },
      }
    );
  };

  return {
    savedAnnotationData,
    getFillColorForFeature,
    getColorForFeature,
    getSettingsForFeature,
    todoOptions: todo?.options,
    activeAnnotationData,
    getFilteredAnnotationItemsByFeatureIndex,
    annotationItems,
    updateAnnotationSettings,
    saveDraftMutation,
    publishAnnotationsMutation,
    publishedAnnotationsSaved,
    draftDataSaved,
    activeAnnotationAssignmentId,
    saveDraft,
    slideAnnotations,
    isSlideAnnotationsLoading: isLoading,
  };
};

export default useAnnotationsForViewer;

export const getShapeSubTypeFromTodoOption = (todoOption: TodoOption): ShapeSubTypes => {
  if (todoOption.className.toLocaleLowerCase() == 'point') {
    return ShapeSubTypes.Point;
  }
  if (includes(todoOption.displayName.toLocaleLowerCase(), 'roi')) {
    return ShapeSubTypes.Rect;
  }
  return ShapeSubTypes.Polygon;
};

// if old annotations are saved with more data than needed for comparison, this function will remove the extra data
const getPropertiesForComparison = (properties: any) => {
  return pick(properties, ['diagnosis', 'markerType', 'shapeSubType', 'markerPositivity']);
};
