import { OrthographicViewport } from '@deck.gl/core/typed';
import { AllGeoJSON, Coord, Feature, lineString, point, radiansToDegrees, Units } from '@turf/helpers';
import { getCoord, getCoords } from '@turf/invariant';
import lineIntersects from '@turf/line-intersect';
import { flattenEach } from '@turf/meta';
import { map } from 'lodash';

import { FeatureOf, FeatureWithProps, LineString, MultiLineString, Point } from '../NebulaGLExtensions/geojson-types';

export type RGBAColor = [number, number, number, number?];

export const TOOLTIP_COLOR = [180, 180, 180];

// TODO edit-modes:  delete once fully on EditMode implementation and just use handle.properties.editHandleType...
// Unwrap the edit handle object from either layer implementation
export function getEditHandleTypeFromEitherLayer(handleOrFeature: any) {
  if (handleOrFeature.__source) {
    return handleOrFeature.__source.object.properties.editHandleType;
  } else if (handleOrFeature.sourceFeature) {
    return handleOrFeature.sourceFeature.feature.properties.editHandleType;
  } else if (handleOrFeature.properties) {
    return handleOrFeature.properties.editHandleType;
  }

  return handleOrFeature.type;
}

export function getEditHandleColor(handle: any): RGBAColor {
  switch (getEditHandleTypeFromEitherLayer(handle)) {
    case 'existing':
      return [0xff, 0x80, 0x00, 0xff];
    case 'snap-source':
      return [0xc0, 0x80, 0xf0, 0xff];
    case 'intermediate':
    default:
      return [0xff, 0xc0, 0x80, 0xff];
  }
}

export const distance2d = (x1: number, y1: number, x2: number, y2: number): number =>
  Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));

/**
 * Calculates the orthographic distance between two {@link Coord|coordinates}.
 *
 * @name coordsOrthographicDistance
 * @param {Coord} from origin coordinate
 * @param {Coord} to destination coordinate
 * @returns {number} distance between the two coordinates
 */
export function coordsOrthographicDistance(from: Coord, to: Coord) {
  var [x1, y1] = getCoord(from);
  var [x2, y2] = getCoord(to);

  return distance2d(x1, y1, x2, y2);
}

/**
 * Interpolates between two numbers based on a given ratio.
 * @param a - The first number.
 * @param b - The second number.
 * @param ratio - The ratio to determine the interpolation between `a` and `b`. Should be between 0 and 1.
 * @returns The interpolated number.
 */
export function interpolate(a: number, b: number, ratio: number): number {
  return a + (b - a) * ratio;
}

/**
 * Takes two {@link Point|points} and finds the geographic bearing between them,
 * i.e. the angle measured in degrees from the north line (0 degrees)
 *
 * @name coordsOrthographicBearing
 * @param {Coord} start starting Point
 * @param {Coord} end ending Point
 * @param {Object} [options={}] Optional parameters
 * @param {boolean} [options.final=false] calculates the final bearing if true
 * @returns {number} bearing in decimal degrees, between -180 and 180 degrees (positive clockwise)
 */
export function coordsOrthographicBearing(
  start: Coord,
  end: Coord,
  options: {
    final?: boolean;
  } = {}
): number {
  // Reverse calculation
  if (options.final === true) {
    return calculateFinalOrthographicBearingFromCoordinates(start, end);
  }

  var [x1, y1] = getCoord(start);
  var [x2, y2] = getCoord(end);

  return radiansToDegrees(Math.atan2(y2 - y1, x2 - x1));
}

/**
 * Calculates Final Bearing
 *
 * @private
 * @param {Coord} start starting Point
 * @param {Coord} end ending Point
 * @returns {number} bearing
 */
function calculateFinalOrthographicBearingFromCoordinates(start: Coord, end: Coord) {
  // Swap start & end
  let bear = coordsOrthographicBearing(end, start);
  bear = (bear + 180) % 360;
  return bear;
}

/**
 * Takes a {@link Point} and calculates the location of a destination point given a distance in
 * degrees, radians, miles, or kilometers; and bearing in degrees.
 * This uses the [Haversine formula](http://en.wikipedia.org/wiki/Haversine_formula) to account for global curvature.
 *
 * @name coordsOrthographicDestination
 * @param {Coord} origin starting point
 * @param {number} distance distance from the origin point
 * @param {number} bearing ranging from -180 to 180
 * @param {Object} [options={}] Optional parameters
 * @param {Object} [options.properties={}] Translate properties to Point
 * @returns {Feature<Point>} destination point
 */
function coordsOrthographicDestination<P extends { [name: string]: any } | null = { [name: string]: any } | null>(
  origin: Coord,
  distance: number,
  bearing: number,
  options: {
    properties?: P;
  } = {}
) {
  // Handle input
  const [x, y] = getCoord(origin);

  const dx = distance * Math.cos((bearing * Math.PI) / 180);
  const dy = distance * Math.sin((bearing * Math.PI) / 180);

  return point([x + dx, y + dy], options.properties) as Feature<Point, P>;
}
/**
 * Takes a {@link Point} and a {@link LineString} and calculates the closest Point on the (Multi)LineString.
 *
 * @name nearestPointOnLine
 * @param {Geometry|Feature<LineString|MultiLineString>} lines lines to snap to
 * @param {Geometry|Feature<Point>|number[]} pt point to snap from
 * @returns {Feature<Point>} closest point on the `line` to `point`. The properties object will contain three values: `index`: closest point was found on nth line part, `dist`: distance between pt and the closest point, `location`: distance along the line between start and the closest point.
 */
export function nearestOrthographicPointOnLine<G extends LineString | MultiLineString>(
  lines: Feature<G> | G,
  pt: Coord
): Feature<
  Point,
  {
    dist: number;
    index: number;
    location: number;
    [key: string]: any;
  }
> {
  if (!lines || !pt) {
    throw new Error('lines and pt are required arguments');
  }

  let closestPt = point([Infinity, Infinity], {
    dist: Infinity,
    index: -1,
    location: -1,
  }) as Feature<Point, { dist: number; index: number; location: number }>;

  let length = 0.0;
  flattenEach(lines, function (line: any) {
    const coords: any = getCoords(line);

    for (let i = 0; i < coords.length - 1; i++) {
      //start
      const start = point(coords[i]) as Feature<Point, { dist: number }>;
      start.properties.dist = coordsOrthographicDistance(pt, start);
      //stop
      const stop = point(coords[i + 1]) as Feature<Point, { dist: number }>;
      stop.properties.dist = coordsOrthographicDistance(pt, stop);
      // sectionLength
      const sectionLength = coordsOrthographicDistance(start, stop);
      //perpendicular
      const heightDistance = Math.max(start.properties.dist, stop.properties.dist);
      const direction = coordsOrthographicBearing(start, stop);
      const perpendicularPt1 = coordsOrthographicDestination(pt, heightDistance, direction + 90);
      const perpendicularPt2 = coordsOrthographicDestination(pt, heightDistance, direction - 90);
      const intersect = lineIntersects(
        lineString([perpendicularPt1.geometry.coordinates, perpendicularPt2.geometry.coordinates]),
        lineString([start.geometry.coordinates, stop.geometry.coordinates])
      );
      let intersectPt: Feature<Point, { dist: number; location: number }> | undefined;

      if (intersect.features.length > 0 && intersect.features[0]) {
        intersectPt = {
          ...(intersect.features[0] as Feature<Point, { dist: number; location: number }>),
          properties: {
            dist: coordsOrthographicDistance(pt, intersect.features[0]),
            location: length + coordsOrthographicDistance(start, intersect.features[0]),
          },
        };
      }

      if (start.properties.dist < closestPt.properties.dist) {
        closestPt = {
          ...start,
          properties: { ...start.properties, index: i, location: length },
        };
      }

      if (stop.properties.dist < closestPt.properties.dist) {
        closestPt = {
          ...stop,
          properties: {
            ...stop.properties,
            index: i + 1,
            location: length + sectionLength,
          },
        };
      }

      if (intersectPt && intersectPt.properties.dist < closestPt.properties.dist) {
        closestPt = {
          ...intersectPt,
          properties: { ...intersectPt.properties, index: i },
        };
      }
      // update length
      length += sectionLength;
    }
  });

  return closestPt;
}

export type NearestPointType = FeatureWithProps<Point, { dist: number; index: number }>;

export function nearestPointOnOrthographicProjectedLine(
  line: FeatureOf<LineString>,
  inPoint: FeatureOf<Point>,
  viewport: OrthographicViewport
): NearestPointType {
  const orthographicViewport = new OrthographicViewport(viewport);
  // Project the line to viewport, then find the nearest point
  const coordinates: Array<Array<number>> = line.geometry.coordinates as any;
  const projectedCoords = coordinates.map(([x, y, z = 0]) => orthographicViewport.project([x, y, z]));
  //@ts-ignore
  const [x, y] = orthographicViewport.project(inPoint.geometry.coordinates);

  let minDistance = Infinity;
  let minPointInfo = {};

  projectedCoords.forEach(([x2, y2], index) => {
    if (index === 0) {
      return;
    }

    const [x1, y1] = projectedCoords[index - 1];

    // line from projectedCoords[index - 1] to projectedCoords[index]
    // convert to Ax + By + C = 0
    const A = y1 - y2;
    const B = x2 - x1;
    const C = x1 * y2 - x2 * y1;

    // https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line
    const div = A * A + B * B;
    const distanceAtIndex = Math.abs(A * x + B * y + C) / Math.sqrt(div);

    // TODO: Check if inside bounds

    if (distanceAtIndex < minDistance) {
      minDistance = distanceAtIndex;
      minPointInfo = {
        index,
        x0: (B * (B * x - A * y) - A * C) / div,
        y0: (A * (-B * x + A * y) - B * C) / div,
      };
    }
  });
  //@ts-ignore
  const { index, x0, y0 } = minPointInfo;
  const [x1, y1, z1 = 0] = projectedCoords[index - 1];
  const [x2, y2, z2 = 0] = projectedCoords[index];

  // calculate what ratio of the line we are on to find the proper z
  const lineLength = distance2d(x1, y1, x2, y2);
  const startToPointLength = distance2d(x1, y1, x0, y0);
  const ratio = startToPointLength / lineLength;
  const z0 = interpolate(z1, z2, ratio);

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      // @ts-expect-error Position type diff
      coordinates: orthographicViewport.unproject([x0, y0, z0]),
    },
    properties: {
      dist: minDistance,
      index: index - 1,
    },
  };
}

export function orthographicTransformTranslate<T extends AllGeoJSON>(
  geojson: T,
  distance: number,
  direction: number,
  options?: {
    units?: Units;
    zTranslation?: number;
    mutate?: boolean;
  }
): T {
  // @ts-ignore
  const newFeatures = map(geojson.features, (feature: any) => {
    const { coordinates } = feature.geometry;
    const newCoordinates = [
      map(coordinates[0], (coordinate: any) => {
        const [x, y, z = 0] = coordinate;
        const zTranslation = options?.zTranslation || 0;
        const dx = distance * Math.cos((direction * Math.PI) / 180);
        const dy = distance * Math.sin((direction * Math.PI) / 180);
        const newCoordinate = [x + dx, y + dy, z + zTranslation];
        return newCoordinate;
      }),
    ];

    return {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: newCoordinates,
      },
    };
  });
  return {
    ...geojson,
    features: newFeatures,
  };
}
