import { SelectProps } from '@mui/material';
import { CellTypingJob, JobType } from 'interfaces/job';
import React, { ReactElement } from 'react';
import JobSelect from './JobSelect';

interface CellTypingJobSelectProps extends Omit<SelectProps, 'onScroll' | 'label'> {
  selectedCellTypingJobId: string | null;
  setSelectedCellTypingJobId: (jobId: string | null) => void;
  onJobSelect: (job: CellTypingJob) => void;
  autoAssignLastJob?: boolean;
  helperText?: string;
  studyId?: string;
}

export const CellTypingJobSelect = ({
  selectedCellTypingJobId,
  setSelectedCellTypingJobId,
  onJobSelect,
  autoAssignLastJob,
  helperText,
  studyId,
  ...selectProps
}: CellTypingJobSelectProps): ReactElement => {
  return (
    <JobSelect
      label="Cell Typing Job"
      jobType={JobType.MultiplexCellTyping}
      selectedJobId={selectedCellTypingJobId}
      setSelectedJobId={setSelectedCellTypingJobId}
      setSelectedJob={onJobSelect}
      autoAssignLastJob={autoAssignLastJob}
      helperText={helperText}
      studyId={studyId}
      {...selectProps}
    />
  );
};
