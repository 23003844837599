import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSignals } from '@preact/signals-react/runtime';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Permission } from 'interfaces/permissionOption';
import { forEach, some, values } from 'lodash';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import { SlideAnnotationEditType } from '../Header/SlideInteractionMenu/slideAnnotations/options';

interface Props {
  selectedSlides: SlideWithChannelAndResults[];
  marginLeft?: number;
  marginRight?: number;
}

const ViewerHints: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  selectedSlides,
  marginLeft,
  marginRight,
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();
  const { annotationsActive } = useAnnotationQueryParams();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        backgroundColor: theme.palette.background.paper,
        width: `calc(100% - ${marginLeft}px - ${marginRight}px)`,
        marginLeft: `${marginLeft}px`,
        marginRight: `${marginRight}px`,
        height: 30,
        alignItems: 'center',
      }}
    >
      {annotationsActive && canAnnotateSlides && <AnnotationsHints selectedSlides={selectedSlides} />}
    </Grid>
  );
};

const AnnotationsHints: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ selectedSlides }) => {
  useSignals();

  const [hideHeatmaps, setHideHeatmaps] = useQueryParam('hideHeatmaps', BooleanParam);
  const [editAnnotationsModeFromUrl] = useQueryParam('editAnnotationsMode', StringParam);
  const [altKeyPressed, setAltKeyPressed] = useState(false);
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyDownForAnnotationsShortcuts = (event: KeyboardEvent) => {
      if (event.key === 'm') {
        setHideHeatmaps(!hideHeatmaps);
      }

      if (event.key === 'Alt') {
        setAltKeyPressed(true);
      }

      if (event.key === 'Shift') {
        setShiftKeyPressed(true);
      }
    };

    const handleKeyUpForAnnotationsShortcuts = (event: KeyboardEvent) => {
      if (event.key === 'Alt') {
        setAltKeyPressed(false);
      }
      if (event.key === 'Shift') {
        setShiftKeyPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDownForAnnotationsShortcuts);
    window.addEventListener('keyup', handleKeyUpForAnnotationsShortcuts);

    return () => {
      window.removeEventListener('keydown', handleKeyDownForAnnotationsShortcuts);
      window.removeEventListener('keyup', handleKeyUpForAnnotationsShortcuts);
    };
  });

  // When hideHeatmapsChanges- update the settings of the current viewers
  useEffect(() => {
    forEach(selectedSlides, (slide) => {
      const currentViewerSettings = slidesLayerVisualizationSettings[slide.viewerIndex].value;
      forEach(values(currentViewerSettings), (slideSettings) => {
        forEach(values(slideSettings), (layerSettings) => {
          layerSettings.value = {
            ...layerSettings.value,
            show: !hideHeatmaps,
          };
        });
      });
      slidesLayerVisualizationSettings[slide.viewerIndex].value = { ...currentViewerSettings };
    });
  }, [hideHeatmaps, selectedSlides]);

  const doViewersHaveShownHeatmaps = some(selectedSlides, (slide) => {
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

    if (!viewerSlideLayerVisualizationSettings) {
      return false;
    }

    const slideSettings = viewerSlideLayerVisualizationSettings?.value?.[slide.id];
    if (!slideSettings) {
      return false;
    }

    return some(slideSettings, (layerSettings) => layerSettings.value.selected);
  });

  const isOnDrawPolygonMode = editAnnotationsModeFromUrl === SlideAnnotationEditType.Polygon;
  const isOnMoveFeatureMode = editAnnotationsModeFromUrl === SlideAnnotationEditType.Move;

  return (
    <>
      {altKeyPressed ? (
        <Grid item>
          <Typography variant="body2">Delete annotation</Typography>
        </Grid>
      ) : shiftKeyPressed && isOnDrawPolygonMode ? (
        <Grid item>
          <Typography variant="body2">Pan the map</Typography>
        </Grid>
      ) : (
        <>
          {doViewersHaveShownHeatmaps && (
            <Grid item>
              <Typography variant="body2">
                {hideHeatmaps ? 'Press m to show heatmaps' : 'Press m to hide heatmaps'}
              </Typography>
            </Grid>
          )}
          {isOnDrawPolygonMode && (
            <Grid item>
              <Typography variant="body2">Shift - pan</Typography>
            </Grid>
          )}
          {isOnMoveFeatureMode && (
            <Grid item>
              <Typography variant="body2">Click a feature to select it</Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant="body2">Alt - delete</Typography>
          </Grid>
        </>
      )}
    </>
  );
};

export default ViewerHints;
