import ExpandIcon from '@mui/icons-material/ExpandMore';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getArtifactsInference, runMultiplexCellTyping } from 'api/platform';
import { getSlidesDataForArtifactsResults } from 'api/study';
import { OrchestrationBySlideByType } from 'components/Pages/CalculateFeatures';
import InferenceModelsForSlides from 'components/Pages/CalculateFeatures/InferenceModelsForSlides';
import { getModelsTypeByModelInferences } from 'components/Pages/CalculateFeatures/InferenceModelsForSlides/utils';
import { BinaryClassifierConfig } from 'interfaces/inferenceArtifacts';
import { MultiplexCellTypingConfig } from 'interfaces/jobs/multiplex/cellTypingParams';
import { filter, forEach, get, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { humanize } from 'utils/helpers';
import { useCasesParams } from 'utils/useCasesParams';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import * as yup from 'yup';
import { PlatformStepper } from '../PlatformStepper';
import { JobNameAndDescriptionStep } from '../utils';
import CellRulesFromPanelSelect from './CellRulesFromPanelSelect';
import { ThresholdJobSelect } from './ThresholdJobSelect';

const SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_TYPING = 'RUN_MULTIPLEX_CELL_TYPING';

export interface CellTypingFormValues {
  jobName?: string;
  jobDescription?: string;
  configParams: MultiplexCellTypingConfig;
}

const validationSchema = [
  yup.object({
    configParams: yup.object({
      cellRules: yup.object().nullable().required('Cell Rules are required'),
      binaryClassifierInferenceArtifactUrls: yup
        .array()
        .required()
        .min(1, 'Binary Classifier Inference Artifact is required'),
      thresholds: yup.object().nullable().required('Threshold Job is required'),
    }),
  }),
  yup.object({}),
];

const defaultValues: CellTypingFormValues = {
  configParams: {
    binaryClassifierInferenceArtifactUrls: [],
    thresholds: null,
    cellRules: null,
  },
};

const defaultPerSlideSample = 5000;

export interface RunMultiplexCellTypingProps {
  onClose: () => void;
}

export const RunMultiplexCellTyping = (props: RunMultiplexCellTypingProps): ReactElement => {
  const { onClose } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];

  const [isStepFailed, setIsStepFailed] = useState<Record<number, boolean>>({});

  const { casesParams, schema, options } = useCasesParams();
  const getEncodedParams = () => encodeQueryParamsUsingSchema(casesParams, schema, options);

  const studyId = casesParams.filters?.studyId;

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<CellTypingFormValues>({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(currentValidationSchema),
  });

  const enableInferenceArtifactsQuery = Boolean(studyId);

  const {
    data: inferenceArtifacts,
    isLoading: isInferenceArtifactsLoading,
    isFetching: isInferenceArtifactsFetching,
  } = useQuery({
    queryKey: [
      'artifactsInference',
      { studyId: studyId, casesParams: getEncodedParams(), configParams: BinaryClassifierConfig },
    ],
    queryFn: ({ signal }) =>
      getArtifactsInference({ studyId, caseParams: getEncodedParams(), configParams: BinaryClassifierConfig }, signal),
    enabled: enableInferenceArtifactsQuery,
  });

  const { data: slides, isFetching: isSlidesFetching } = useQuery({
    queryKey: ['slidesDataForArtifactsResults', { studyId, caseParams: getEncodedParams() }],
    queryFn: ({ signal }) => getSlidesDataForArtifactsResults(studyId, getEncodedParams(), signal),
    enabled: Boolean(studyId),
  });

  const [selectedOrchestrations, setSelectedOrchestrations] = useState<OrchestrationBySlideByType>({});
  const [selectedThresholdJobId, setSelectedThresholdJobId] = useState<string | null>(null);
  const [perSlideSample, setPerSlideSample] = useState<boolean>(false);

  const handleSelectedOrchestrationsChange = (orchestrations: OrchestrationBySlideByType) => {
    setSelectedOrchestrations(orchestrations);

    const finalInferenceResultsArtifactUrls: string[] = [];

    const slidesWithResults = filter(slides, (slide) => {
      return !isEmpty(orchestrations[slide.slideId]);
    });
    forEach(slidesWithResults, (slide) => {
      forEach(orchestrations[slide.slideId], (orchestrationData, modelType) => {
        finalInferenceResultsArtifactUrls.push(
          orchestrationData?.orchestration.orchestrationResultArtifactUrlPattern.replace('{slide_id}', slide.slideId)
        );
      });
    });

    setValue('configParams.binaryClassifierInferenceArtifactUrls', finalInferenceResultsArtifactUrls);
    trigger('configParams.binaryClassifierInferenceArtifactUrls');
  };

  const checkValidationAndSetIsStepFailed = (stepIndex: number, objectToValidate: Record<string, any>) => {
    validationSchema[stepIndex]
      .validate(objectToValidate)
      .then(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: false,
        }));
      })
      .catch(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: true,
        }));
      });
  };

  const runMultiplexCellTypingMutation = useMutation(runMultiplexCellTyping, {
    onError: () => {
      enqueueSnackbar('Error occurred, Multiplex Cell Typing failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Multiplex Cell Typing Started', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_TYPING);
    },
  });

  const onSubmit: SubmitHandler<CellTypingFormValues> = async (data) => {
    runMultiplexCellTypingMutation.mutate({
      ...casesParams,
      jobName: data.jobName,
      jobDescription: data.jobDescription,
      configParams: data.configParams,
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Multiplex Cell Typing to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_TYPING,
      autoHideDuration: null,
    });

    onClose();
  };

  const validateCellTypingParams = async () => {
    checkValidationAndSetIsStepFailed(0, {
      configParams: watch('configParams'),
    });
  };

  const steps = [
    {
      label: 'Cell Typing Configuration',
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {get(errors, 'configParams.binaryClassifierInferenceArtifactUrls') && (
              <Typography color="error">
                {humanize(get(errors, 'configParams.binaryClassifierInferenceArtifactUrls')?.message)}
              </Typography>
            )}
            <InferenceModelsForSlides
              studyId={studyId}
              slides={slides}
              modelsType={getModelsTypeByModelInferences(inferenceArtifacts)}
              inferenceModels={inferenceArtifacts}
              isLoading={isInferenceArtifactsLoading || isInferenceArtifactsFetching || isSlidesFetching}
              selectedOrchestrations={selectedOrchestrations}
              setSelectedOrchestrations={handleSelectedOrchestrationsChange}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name={'configParams.thresholds'}
              render={({ field: { onChange } }) => (
                <ThresholdJobSelect
                  selectedThresholdJobId={selectedThresholdJobId}
                  setSelectedThresholdJobId={setSelectedThresholdJobId}
                  onJobSelect={(job) => {
                    const thresholds = job?.results?.perMarkerThresholds;
                    if (thresholds) onChange(thresholds);
                  }}
                  error={Boolean(get(errors, 'configParams.thresholds')?.message)}
                  helperText={
                    // @ts-ignore
                    humanize(get(errors, 'configParams.thresholds')?.message)
                  }
                  autoAssignLastJob
                />
              )}
            />
          </Grid>
          <Grid item container>
            <CellRulesFromPanelSelect
              studyId={studyId}
              onCellRulesChange={(newCellRules) => {
                setValue('configParams.cellRules', newCellRules);
                trigger('configParams.cellRules');
              }}
              showRequiredError={Boolean(get(errors, 'configParams.cellRules')?.message)}
              autoAssignLastPanel
            />
          </Grid>
          <Grid item container direction="row">
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="body2">Per Slide Sample: </Typography>
              </Grid>
              <Grid item>
                <Checkbox
                  checked={perSlideSample}
                  onChange={(e) => {
                    setPerSlideSample(e.target.checked);
                    if (e.target.checked && watch('configParams.perSlideSample') === undefined) {
                      setValue('configParams.perSlideSample', defaultPerSlideSample);
                    } else if (!e.target.checked && watch('configParams.perSlideSample') !== undefined) {
                      setValue('configParams.perSlideSample', undefined);
                    }
                  }}
                />
              </Grid>
            </Grid>
            {perSlideSample && (
              <Grid item>
                <Controller
                  control={control}
                  name={'configParams.perSlideSample'}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="Sample"
                      type="number"
                      fullWidth
                      value={value}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                      error={Boolean(get(errors, 'configParams.perSlideSample')?.message)}
                      helperText={humanize(get(errors, 'configParams.perSlideSample')?.message)}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ),
      onNextOrBackClick: validateCellTypingParams,
    },
    {
      label: 'Job Name and Description',
      subLabel: activeStep > 0 && watch('jobName'),
      optional: true,
      content: <JobNameAndDescriptionStep control={control} errors={errors} register={register} />,
    },
  ];

  return (
    <>
      <PlatformStepper
        handleSubmit={handleSubmit(onSubmit)}
        steps={steps}
        setActiveStepForValidation={setActiveStep}
        isStepFailed={isStepFailed}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandIcon />}>Cell Typing Params Summary (JSON)</AccordionSummary>
        <AccordionDetails>
          <Typography component="pre">
            {JSON.stringify(
              {
                jobName: watch('jobName'),
                jobDescription: watch('jobDescription'),
                configParams: watch('configParams'),
              },
              null,
              2
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
