import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import {
  editTypeToShapeSubTypeMap,
  ShapeSubTypes,
} from 'components/Procedure/Header/SlideInteractionMenu/slideAnnotations/options';
import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { TodoOption } from 'interfaces/annotation';
import { find, get, times } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';
import useAnnotationsForViewer from './useAnnotations';

export interface AnnotationState {
  name: string;
  positive?: boolean; // for marker annotations (to know if now annotating cells that are positive or negative to the marker)
}

// what we save in the signal
interface AnnotationSettings {
  currentClassToAnnotatePerGeometry: {
    [K in ShapeSubTypes]: AnnotationState;
  };
}

export const viewerAnnotationSettings = times(MAX_VIEWERS, () => signal<AnnotationSettings | null>(null));

const useActiveClassToAnnotate = ({ slideId, viewerIndex }: { slideId: string; viewerIndex: number }): TodoOption => {
  useSignals();

  const { annotationItems } = useAnnotationsForViewer({ slideId, viewerIndex });

  const [editAnnotationsModeFromUrl] = useQueryParam('editAnnotationsMode', StringParam);

  if (viewerAnnotationSettings[viewerIndex]?.value) {
    const currentShapeSubtype = get(editTypeToShapeSubTypeMap, editAnnotationsModeFromUrl, '');
    const { currentClassToAnnotatePerGeometry } = viewerAnnotationSettings[viewerIndex].value;
    const currentClassToAnnotate = get(currentClassToAnnotatePerGeometry, currentShapeSubtype, null);
    if (currentClassToAnnotate) {
      const matchingOption = find(annotationItems, { name: currentClassToAnnotate.name });

      return matchingOption ? { ...matchingOption, positive: currentClassToAnnotate.positive } : ({} as TodoOption);
    }
  }

  return {} as TodoOption;
};

export default useActiveClassToAnnotate;
