import { Grid, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getSlideRegistrations } from 'api/slideRegistrations';
import CellRulesFromPanelSelect from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/CellRulesFromPanelSelect';
import { CellTypingJobSelect } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/CellTypingJobSelect';
import { ThresholdJobSelect } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/ThresholdJobSelect';
import { AnnotationAssignment } from 'interfaces/annotation';
import {
  ClassificationBinningParams,
  InferenceModelData,
  ModelInference,
  OrchestrationInference,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import { CellTypingJob, ThresholdJob } from 'interfaces/job';
import { CellRules } from 'interfaces/jobs/multiplex/cellTypingParams';
import {
  filter,
  findIndex,
  first,
  flatMap,
  forEach,
  get,
  groupBy,
  isEmpty,
  keyBy,
  keys,
  map,
  omit,
  pickBy,
  set,
  size,
  values,
} from 'lodash';
import React, { useEffect } from 'react';
import {
  AssignmentByStain,
  ModelClassificationById,
  OrchestrationBySlideByFlowClassName,
  OrchestrationBySlideByType,
  SelectedAssignmentDetails,
  SlideRegistrationDetailsByOrchestration,
} from '..';
import AssignmentStain from './AssignmentStain';
import InferenceStain from './InferenceStain';
import { getMostRelevantRegistrationPerPair, getRegistrationDetails } from './SlideRegistrations/OrchIdRegistrations';
import SlideRegistrationsSection from './SlideRegistrations/SlideRegistrationsSection';
import Summary from './Summary';

export interface ModelsByStainByType {
  [stain: string]: {
    [modelType: string]: {
      [modelUrl: string]: ModelInference;
    };
  };
}

export interface OrchestrationByStainByFlowClassName {
  [stain: string]: {
    [flowClassName: string]: OrchestrationInference[];
  };
}

export interface MultiplexGeneralInputsProps {
  selectedThresholdJobId: string | null;
  setSelectedThresholdJobId: (jobId: string | null) => void;
  onThresholdJobSelect: (job: ThresholdJob) => void;
  onCellRulesChange: (cellRules: CellRules) => void;
  selectedCellTypingJobId: string | null;
  setSelectedCellTypingJobId: (jobId: string | null) => void;
  onCellTypingJobSelect: (job: CellTypingJob) => void;
}

export interface InferenceModelsForSlidesProps {
  studyId: string;
  isLoading: boolean;
  slides: SlideInferenceResults[];
  slideStainTypes?: string[];
  inferenceModels: ModelInference[];
  postprocessedArtifacts?: OrchestrationInference[];
  displayAssignmentAnnotations?: boolean;
  displayPostProcessing?: boolean;
  assignmentAnnotationsByStainType?: { [stainType: string]: AnnotationAssignment[] };
  selectedAssignments?: AssignmentByStain;
  setSelectedAssignments?: React.Dispatch<React.SetStateAction<AssignmentByStain>>;
  modelsType: string[];
  selectedOrchestrations: OrchestrationBySlideByType;
  setSelectedOrchestrations: (orchestrations: OrchestrationBySlideByType) => void;
  selectedPostprocessedOrchestrations?: OrchestrationBySlideByFlowClassName;
  setSelectedPostprocessedOrchestrations?: React.Dispatch<React.SetStateAction<OrchestrationBySlideByFlowClassName>>;
  setModelClassificationByModelId?: (modelId: string, classification: string) => void;
  modelClassificationByModelId?: ModelClassificationById;
  setIntensityClassificationBinning?: (modelId: string, binning: ClassificationBinningParams) => void;
  encodedQueryForRegistrations?: string;
  selectedSlideRegistrations?: SlideRegistrationDetailsByOrchestration | undefined;
  setSelectedSlideRegistration?: (value: React.SetStateAction<SlideRegistrationDetailsByOrchestration>) => void;
  multiplexGeneralInputsProps?: MultiplexGeneralInputsProps;
}

const InferenceModelsForSlides: React.FC<React.PropsWithChildren<InferenceModelsForSlidesProps>> = ({
  studyId,
  isLoading,
  slides,
  slideStainTypes,
  inferenceModels,
  postprocessedArtifacts,
  modelsType,
  displayAssignmentAnnotations = false,
  displayPostProcessing = false,
  assignmentAnnotationsByStainType,
  selectedAssignments,
  setSelectedAssignments,
  selectedOrchestrations,
  setSelectedOrchestrations,
  selectedPostprocessedOrchestrations,
  setSelectedPostprocessedOrchestrations,
  modelClassificationByModelId,
  setModelClassificationByModelId,
  setIntensityClassificationBinning,
  encodedQueryForRegistrations,
  selectedSlideRegistrations,
  setSelectedSlideRegistration,
  multiplexGeneralInputsProps,
}) => {
  const {
    selectedThresholdJobId,
    setSelectedThresholdJobId,
    onThresholdJobSelect,
    onCellRulesChange,
    selectedCellTypingJobId,
    setSelectedCellTypingJobId,
    onCellTypingJobSelect,
  } = multiplexGeneralInputsProps ?? {};
  const slidesById = keyBy(slides, 'slideId');

  const setSelectedOrchestrationsByClassificationType = (modelType: string, previousModelType?: string) => {
    const slideIdsWithPreviousModelType = filter(
      keys(selectedOrchestrations),
      (slideId) => !isEmpty(get(selectedOrchestrations[slideId], previousModelType))
    );

    forEach(slideIdsWithPreviousModelType, (slideId) => {
      const newOrchestrations = { ...selectedOrchestrations };

      if (!isEmpty(modelType)) {
        newOrchestrations[slideId] = {
          ...newOrchestrations[slideId],
          [modelType]: get(newOrchestrations[slideId], previousModelType),
        };
      }

      newOrchestrations[slideId] = omit(newOrchestrations[slideId], previousModelType);

      setSelectedOrchestrations(newOrchestrations);
    });
  };

  const setSelectedOrchestrationsByTypeByStain = (
    slideIds: string[],
    model?: InferenceModelData,
    modelType?: string,
    orchestration?: OrchestrationInference
  ) => {
    const newOrchestrations = { ...selectedOrchestrations };

    if (isEmpty(model)) {
      forEach(slideIds, (slideId) => {
        newOrchestrations[slideId] = {};
      });
    } else if (isEmpty(orchestration)) {
      forEach(slideIds, (slideId) => {
        newOrchestrations[slideId] = omit(newOrchestrations[slideId], modelType);
      });
    } else {
      forEach(slideIds, (slideId) => {
        newOrchestrations[slideId] = {
          ...newOrchestrations[slideId],
          [modelType]: {
            orchestration,
            model,
          },
        };
      });
    }

    setSelectedOrchestrations(newOrchestrations);
  };

  const setSelectedPostprocessedOrchestrationsByFlowClassNameByStain = (
    slideIds: string[],
    flowClassName?: string,
    orchestration?: OrchestrationInference
  ) => {
    setSelectedPostprocessedOrchestrations((prev) => {
      const newOrchestrations = { ...prev };

      if (isEmpty(flowClassName)) {
        forEach(slideIds, (slideId) => {
          newOrchestrations[slideId] = {};
        });
      } else if (isEmpty(orchestration)) {
        forEach(slideIds, (slideId) => {
          newOrchestrations[slideId] = omit(newOrchestrations[slideId], flowClassName);
        });
      } else {
        forEach(slideIds, (slideId) => {
          newOrchestrations[slideId] = {
            ...newOrchestrations[slideId],
            [flowClassName]: {
              orchestration,
            },
          };
        });
      }

      return newOrchestrations;
    });
  };
  const addNewOverrideAssignment = (stain: string, newAssignment: SelectedAssignmentDetails) => {
    setSelectedAssignments((prev) => {
      const newAssignments = { ...prev };
      newAssignments[stain] = [...(newAssignments[stain] ?? []), newAssignment];

      return newAssignments;
    });
  };

  const updateSelectedAssignment = (stain: string, updatedAssignment: SelectedAssignmentDetails) => {
    setSelectedAssignments((prev) => {
      const newAssignments = { ...prev };
      const updatedAssignmentIndex = findIndex(newAssignments[stain], { id: updatedAssignment.id });
      newAssignments[stain][updatedAssignmentIndex] = updatedAssignment;
      return newAssignments;
    });
  };

  const removeSelectedAssignment = (stain: string, assignmentOverrideId: string) => {
    setSelectedAssignments((prev) => {
      const newAssignments = { ...prev };
      newAssignments[stain] = filter(newAssignments[stain], (assignment) => assignment.id !== assignmentOverrideId);
      return newAssignments;
    });
  };

  const getStainOfOrchestration = (slide_ids: string[]) => {
    return slidesById[first(slide_ids)]?.stainingType;
  };

  const modelsByStain: ModelsByStainByType = {};

  forEach(inferenceModels, (inferenceModel) => {
    forEach(inferenceModel.orchestrations, (orchestration) => {
      const stain = getStainOfOrchestration(orchestration.slideIds);
      const modelType = inferenceModel.modelType;
      const modelUrl = inferenceModel.modelUrl;

      // Initialize nested structure if not already present
      set(modelsByStain, [stain], get(modelsByStain, [stain], {}));
      set(modelsByStain, [stain, modelType], get(modelsByStain, [stain, modelType], {}));
      set(
        modelsByStain,
        [stain, modelType, modelUrl],
        get(modelsByStain, [stain, modelType, modelUrl], {
          ...omit(inferenceModel, 'orchestrations'),
          orchestrations: [],
        })
      );

      modelsByStain[stain][modelType][modelUrl].orchestrations.push(orchestration);
    });
  });

  const postprocessedArtifactsByStain: OrchestrationByStainByFlowClassName = {};

  forEach(postprocessedArtifacts, (postprocessedArtifact) => {
    const stain = getStainOfOrchestration(postprocessedArtifact.slideIds);
    const flowClassName = postprocessedArtifact.params.flowClassName;

    // Initialize nested structure if not already present
    set(postprocessedArtifactsByStain, [stain], get(postprocessedArtifactsByStain, [stain], {}));
    set(
      postprocessedArtifactsByStain,
      [stain, flowClassName],
      get(postprocessedArtifactsByStain, [stain, flowClassName], [])
    );

    postprocessedArtifactsByStain[stain][flowClassName].push(postprocessedArtifact);
  });

  const canLoadRegistrations = Boolean(encodedQueryForRegistrations) && size(slideStainTypes) > 1;

  const {
    data: registrationsData,
    isFetching: isRegistrationsFetching,
    isError: isRegistrationsError,
    isFetched: isRegistrationsFetched,
  } = useQuery({
    queryKey: ['slide_registrations', encodedQueryForRegistrations],
    queryFn: () => getSlideRegistrations(encodedQueryForRegistrations),
    enabled: canLoadRegistrations,
  });

  useEffect(() => {
    // when data fetched, set selected registrations for each pair of slides- the latest (approved if exists) registration
    if (
      isRegistrationsFetched &&
      !isRegistrationsFetching &&
      !isRegistrationsError &&
      selectedSlideRegistrations === undefined &&
      !isEmpty(registrationsData)
    ) {
      const selectedRegistrations = getMostRelevantRegistrationPerPair(registrationsData);
      const slideRegistrationsByOrchestration = groupBy(selectedRegistrations, 'orchestrationId');
      const slideRegistrationDetailsByOrchestration: SlideRegistrationDetailsByOrchestration = {};
      forEach(slideRegistrationsByOrchestration, (registrations, orchestrationId) => {
        slideRegistrationDetailsByOrchestration[orchestrationId] = map(registrations, (registration) =>
          getRegistrationDetails(registration, studyId)
        );
      });

      setSelectedSlideRegistration(slideRegistrationDetailsByOrchestration);
    }
  }, [
    registrationsData,
    isRegistrationsFetched,
    isRegistrationsFetching,
    isRegistrationsError,
    selectedSlideRegistrations,
  ]);

  if (isLoading) {
    return <Skeleton variant="rectangular" height={150} />;
  } else if (!displayAssignmentAnnotations && isEmpty(inferenceModels)) {
    return <Typography>No inference models found for this study</Typography>;
  } else if (
    displayAssignmentAnnotations &&
    isEmpty(inferenceModels) &&
    isEmpty(flatMap(values(assignmentAnnotationsByStainType)))
  ) {
    return <Typography>No inference models or annotation assignments found for this study</Typography>;
  } else {
    return (
      <Grid container spacing={2} mb={1}>
        <Grid item container xs={6} spacing={5} py={1} direction="column" overflow="auto">
          <Grid item container spacing={1}>
            <Grid item>
              <Typography>Inference Results:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} direction="column">
                {map(modelsByStain, (stainModelInferences, stain) => {
                  return (
                    <Grid item key={stain}>
                      <InferenceStain
                        studyId={studyId}
                        key={stain}
                        stain={stain}
                        stainModelInferences={stainModelInferences}
                        slides={keyBy(slides, 'slideId')}
                        selectedOrchestrations={selectedOrchestrations}
                        setSelectedOrchestrations={setSelectedOrchestrationsByTypeByStain}
                        modelClassificationByModelId={modelClassificationByModelId}
                        setModelClassificationByModelId={setModelClassificationByModelId}
                        setSelectedOrchestrationsByClassificationType={setSelectedOrchestrationsByClassificationType}
                        setIntensityClassificationBinning={setIntensityClassificationBinning}
                        defaultExpanded={size(modelsByStain) === 1}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          {displayAssignmentAnnotations && (
            <Grid item container spacing={1}>
              <Grid item>
                <Typography>Assignments Results:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} direction="column">
                  {map(
                    pickBy(
                      assignmentAnnotationsByStainType,
                      (annotationAssignments) => !isEmpty(annotationAssignments)
                    ),
                    (annotationAssignment, stain) => {
                      return (
                        <Grid item key={stain}>
                          <AssignmentStain
                            stain={stain}
                            annotationAssignments={annotationAssignment}
                            selectedAssignments={selectedAssignments[stain]}
                            addNewOverrideAssignment={(newAssignment) => addNewOverrideAssignment(stain, newAssignment)}
                            updateSelectedAssignment={(updatedAssignment) =>
                              updateSelectedAssignment(stain, updatedAssignment)
                            }
                            removeSelectedAssignment={(assignmentOverrideId) =>
                              removeSelectedAssignment(stain, assignmentOverrideId)
                            }
                            defaultExpanded={size(assignmentAnnotationsByStainType) === 1}
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {displayPostProcessing && (
            <Grid item container spacing={1}>
              <Grid item>
                <Typography>Post Processed Results:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} direction="column">
                  {map(postprocessedArtifactsByStain, (orchestrationByFlowClassName, stain) => {
                    return (
                      <Grid item key={stain}>
                        <InferenceStain
                          studyId={studyId}
                          key={stain}
                          stain={stain}
                          orchestrationByFlowClassName={orchestrationByFlowClassName}
                          slides={keyBy(slides, 'slideId')}
                          selectedPostprocessedOrchestrations={selectedPostprocessedOrchestrations}
                          setSelectedPostprocessedOrchestrations={
                            setSelectedPostprocessedOrchestrationsByFlowClassNameByStain
                          }
                          defaultExpanded={size(postprocessedArtifactsByStain) === 1}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
          {canLoadRegistrations && (
            <SlideRegistrationsSection
              studyId={studyId}
              allRegistrations={registrationsData}
              isLoading={isRegistrationsFetching}
              isError={isRegistrationsError}
              selectedRegistrations={selectedSlideRegistrations || {}}
              setSelectedRegistrations={setSelectedSlideRegistration}
            />
          )}
          {Boolean(multiplexGeneralInputsProps) && (
            <Grid item>
              <Typography>General Inputs:</Typography>
              <Grid container spacing={1} direction="column" mt={1}>
                <Grid item>
                  <ThresholdJobSelect
                    selectedThresholdJobId={selectedThresholdJobId}
                    setSelectedThresholdJobId={setSelectedThresholdJobId}
                    onJobSelect={onThresholdJobSelect}
                    autoAssignLastJob={true}
                    studyId={studyId}
                  />
                </Grid>
                <Grid item>
                  <CellRulesFromPanelSelect
                    studyId={studyId}
                    onCellRulesChange={onCellRulesChange}
                    autoAssignLastPanel
                  />
                </Grid>
                <Grid item>
                  <CellTypingJobSelect
                    selectedCellTypingJobId={selectedCellTypingJobId}
                    setSelectedCellTypingJobId={setSelectedCellTypingJobId}
                    onJobSelect={onCellTypingJobSelect}
                    autoAssignLastJob
                    studyId={studyId}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <Summary
            modelsType={modelsType}
            slides={keyBy(slides, 'slideId')}
            selectedOrchestrations={selectedOrchestrations}
            selectedPostprocessedOrchestrations={selectedPostprocessedOrchestrations}
            selectedAssignments={selectedAssignments}
            removeSelectedModels={(slideIds) => {
              setSelectedOrchestrationsByTypeByStain(slideIds);
              setSelectedPostprocessedOrchestrationsByFlowClassNameByStain(slideIds);
            }}
            displayAssignmentAnnotations={displayAssignmentAnnotations}
            selectedSlideRegistrationsByOrchestration={selectedSlideRegistrations || {}}
          />
        </Grid>
      </Grid>
    );
  }
};

export default InferenceModelsForSlides;
